import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface StorageContextType {
  profilePicUpdated: number;
  setProfilePicUpdated: React.Dispatch<React.SetStateAction<number>>;
}

// Create a provider component
interface StorageProviderProps {
  children: ReactNode;
}

// Create a new context
const StorageContext = createContext<StorageContextType | undefined>(undefined);

// Create a custom hook to access and update the context value
export function useStorage(): StorageContextType {
  const context = useContext(StorageContext);
  if (!context) {
    throw new Error('useStorage must be used within a StorageProvider');
  }
  return context;
}

export function StorageProvider({ children }: StorageProviderProps) {
  const [profilePicUpdated, setProfilePicUpdated] = useState<number>(0);

  // Define the context value
  const contextValue: StorageContextType = {
    profilePicUpdated,
    setProfilePicUpdated,
  };

  // Return the provider component with the context value
  return (
    <StorageContext.Provider value={contextValue}>
      {children}
    </StorageContext.Provider>
  );
}
