import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  postMessage,
  deleteMessage,
  getMessageForTwoMutualUser,
} from 'src/services/message-service/message-service';

import { uploadUserAttachment } from 'src/services/user-service/user-servicec';
import {
  faArrowRight,
  faClose,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MessageContext } from 'src/context/message.context';
import { dataUrlToFile, resolveBEAsset } from 'src/utils/urls';
import { IUser } from 'src/services/user-service/user-service.interface';
import styles from './chat-window-mobile.module.css';
import { Link, useNavigate } from 'react-router-dom';

interface Message {
  updatedOn(updatedOn: any): unknown;
}

interface GroupedMessages {
  [date: string]: Message[];
}

const ChatWindowMobile: React.FC<any> = ({
  messageListData,
  handleRefreshClick,
}) => {
  const { openChatWindow, closeChatWindow } = useContext(MessageContext);
  const [message, setMessage] = useState<any>('');
  const [messageList, setMessageList] = useState<any>([]);
  const [showDeleteButton, setShowDeleteButton] = useState<any>(null);
  const [selectedMessageId, setSelectedMessageId] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const UserInfo: IUser = JSON.parse(localStorage.getItem('user') || '');

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (message.trim() !== '') {
        setMessage([...message, message]);
        sendMessage(e);
        setMessage('');
      }
    }
  };

  // const handleFileChange = (event: any) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const maxSize = 1024 * 1024; // 1MB
  //     if (file.size > maxSize) {
  //       alert('File size exceeds 1MB. Please choose a smaller file.');
  //       event.target.value = '';
  //     } else {
  //       setSelectedFile(file);
  //     }
  //   }
  // };

  const resizeImage = (file: File, targetSizeKB: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

          const maxSize = targetSizeKB * 1024;
          let width = img.width;
          let height = img.height;

          // maintaining aspect ratio
          const ratio = width / height;
          if (width > height) {
            width = Math.min(Math.sqrt(maxSize / ratio), img.width);
            height = width / ratio;
          } else {
            height = Math.min(Math.sqrt(maxSize * ratio), img.height);
            width = height * ratio;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          const resizedDataUrl = canvas.toDataURL(file.type);
          resolve(resizedDataUrl);
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const resizedDataUrl = await resizeImage(file, 300); // Resize the image to target size of 1MB
        const resizedFile = await dataUrlToFile(resizedDataUrl); // Convert data URL to File object
        setSelectedFile(resizedFile);
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    }
  };

  const handleDoubleClick = (messageId: any) => {
    setShowDeleteButton(true);
    setSelectedMessageId(messageId);
  };

  const handleDeleteMessage = async () => {
    try {
      await deleteMessage(selectedMessageId);
      setShowDeleteButton(false);
      getMessageOfSelectedUserWithCurrent();
    } catch (error) {
      console.error('failed', error);
    }
  };

  const sendMessage = async (e: React.MouseEvent) => {
    try {
      let attachment = [];
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const fileResponse = await uploadUserAttachment(
          formData,
          localStorage.getItem('token')
        );
        attachment.push(fileResponse);
      }
      let contentPayload = {
        type: 'text',
        content: message,
      };
      let payload = {
        content: contentPayload,
        userId: messageListData?.user?._id,
        attachments: attachment,
      };
      if (message !== '') {
        await postMessage(payload);
        await handleRefreshClick(e);
        await getMessageOfSelectedUserWithCurrent();
        setSelectedFile(null);
        setMessage('');
      }
    } catch (error) {
      console.error('failed', error);
    }
  };
  useEffect(() => {
    getMessageOfSelectedUserWithCurrent();
  }, [openChatWindow]);

  const getMessageOfSelectedUserWithCurrent = async (e?: React.MouseEvent) => {
    try {
      e && e.stopPropagation();
      const latestMessage = await getMessageForTwoMutualUser(
        messageListData?.user?._id,
        UserInfo?._id
      );
      setMessageList(groupMessagesByDate(latestMessage.data));
    } catch (error) {
      console.error('failed', error);
    }
  };

  const groupMessagesByDate = (messages: Message[]): GroupedMessages => {
    const groupedMessages: GroupedMessages = {};

    messages.forEach((message) => {
      const messageDate = formatDate(message.updatedOn);
      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }
      groupedMessages[messageDate].push(message);
    });

    return groupedMessages;
  };

  const formatDate = (timeStamp: any) => {
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(timeStamp).toLocaleDateString(undefined, options);
  };

  const handleImageUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formatMessage = (content: any) => {
    const messageContent = content?.content;
    let slicedContent = '';
    if (messageContent) {
      slicedContent =
        messageContent.length < 200
          ? messageContent
          : `${messageContent.substring(0, 200)}...`;
    } else {
      slicedContent = messageContent;
    }
    if (typeof content === 'object') {
      if (content?.type === 'text') {
        return content?.content ? <p>{content?.content}</p> : <></>;
      } else if (content?.type === 'link') {
        return (
          <a
            key={content?.content}
            href={content?.url}
            className="messagelinkWrapper"
            dangerouslySetInnerHTML={{ __html: slicedContent }}
          />
        );
      }
    } else {
      return content;
    }
    return content;
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [messageList, openChatWindow]);

  const handleDiscardImage = () => {
    setSelectedFile(null);
    //@ts-ignore
    fileInputRef.current.value = null;
  };
  const navigate = useNavigate();

  const handleCloseWindow = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate('/message');
    closeChatWindow(messageListData?.user?._id);
  };

  const currentUrl = window.location.href;
  useEffect(() => {
    if (currentUrl.includes('/message')) {
      closeChatWindow(messageListData?.user?._id);
    }
  }, [currentUrl]);

  return (
    <div className={styles['chat-window']}>
      <header className={styles['chat-window-header']}>
        <div className={styles['header-content']}>
          <img
            src="/images/back-arrow.svg"
            alt="bacb-btn"
            className={styles['back-btn']}
            onClick={handleCloseWindow}
          />
          <img
            src={
              messageListData?.user?.picture?.path
                ? resolveBEAsset(messageListData?.user?.picture?.path || '')
                : '/images/defaultProfilePic.svg'
            }
            className={styles['user-img']}
            alt={`Profile of ${messageListData?.user?.name}`}
          />
          <a
            style={{ textDecoration: 'none' }}
            href={`profile?profileId=${messageListData?.user?._id}`}
          >
            <div className={styles['header-user-name']}>
              {messageListData?.user?.name}{' '}
              {messageListData?.user?.isProfessional && (
                <img
                  src="/images/prof-verification.png"
                  alt="VerifiedLogo"
                  style={{
                    height: '15px',
                    width: '15px',
                  }}
                />
              )}
            </div>
          </a>
        </div>
        <div className={styles['actions']}>
          <button
            className={styles['refresh-button']}
            onClick={(e) => getMessageOfSelectedUserWithCurrent(e)}
          >
            <img src="/images/refresh-cw.svg" alt="refreshIcon" height="18px" />
          </button>
        </div>
      </header>
      <div className={styles['message-container']} ref={scrollContainerRef}>
        {Object.keys(messageList).map((date) => (
          <div key={date}>
            <div
              style={{
                textAlign: 'center',
                color: 'var(--grey-grey-30, #b3b3b3)',
                fontSize: '0.75rem',
                letterSpacing: '0.5px',
                margin: '1rem 0',
              }}
            >
              {date}
            </div>
            <div className="message-content">
              {messageList[date].map((message: any, index: any) => (
                <div
                  key={index}
                  className={`message-row ${
                    message.userId === messageListData?.user?._id
                      ? 'sender'
                      : 'receiver'
                  }`}
                  onClick={() => handleDoubleClick(message.id)}
                >
                  <div className="img-wrapper">
                    <img
                      src={
                        message?._userId?.picture?.path
                          ? resolveBEAsset(
                              message?._userId?.picture?.path || ''
                            )
                          : '/images/defaultProfilePic.svg'
                      }
                      style={{ height: '15px', width: '15px' }}
                      alt={`Profile of ${message?._userId?.name}`}
                    />
                  </div>
                  {message.attachments && (
                    <div className={`message`}>
                      {formatMessage(message?.content)}
                    </div>
                  )}

                  {showDeleteButton &&
                    selectedMessageId === message.id &&
                    UserInfo._id === message?._userId?._id && (
                      <button
                        className="trash-icon"
                        onClick={handleDeleteMessage}
                      >
                        <FontAwesomeIcon icon={faTrash} size="sm" />
                      </button>
                    )}
                  {message.attachments && message.attachments.length > 0 && (
                    <div className="message-attachments">
                      {message.attachments.map(
                        (attachment: any, attachmentIndex: any) => (
                          <a
                            key={attachmentIndex}
                            href={`${process.env.REACT_APP_API_HOST}${attachment.path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_HOST}${attachment.path}`}
                              alt={`Attachment ${attachmentIndex}`}
                              style={{ height: '100px', width: '100px' }}
                            />
                          </a>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <footer className={styles['chat-footer']}>
        <div>
          <textarea
            placeholder="Write a message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          ></textarea>
        </div>
        <div>
          {selectedFile && (
            <div className={styles['image-details']}>
              <p>
                {selectedFile.name}{' '}
                <FontAwesomeIcon
                  onClick={() => handleDiscardImage()}
                  color="#dc3545"
                  icon={faClose}
                  style={{ cursor: 'pointer' }}
                />
              </p>
            </div>
          )}
        </div>
        <div className={styles['chat-footer-action']}>
          <input
            type="file"
            accept="image/*"
            id="image-upload"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            multiple={false}
          />
          <label htmlFor="image-upload" onClick={() => handleImageUploadClick}>
            <img src="/images/imageIcon.png" alt="attach" />
            <span>Image</span>
          </label>
          <button className={styles['footer-send-btn']} onClick={sendMessage}>
            Send
            <FontAwesomeIcon icon={faArrowRight} style={{ color: '#fff' }} />
          </button>
        </div>
      </footer>
    </div>
  );
};

export default React.memo(ChatWindowMobile);
