import React, { useState, useEffect } from 'react';
import styles from './bottombar.module.css';
import { useNavigate } from 'react-router-dom';
import SignupModal from '../stepper/SignupModal';

import JobsModal from '../jobs/JobsModal';

import classNames from 'classnames';
import BottomBarProfImage from '../utils/BottomBarProfImage';

export default function BottomBar() {
  const [isDashboard, setIsDashboard] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isEvents, setIsEvents] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [professionalSearch, setProfessionalSearch] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('/dashboard')) {
      setIsDashboard(true);
    }
    if (currentUrl.includes('/events')) {
      setIsEvents(true);
    }
    if (currentUrl.includes('/notification')) {
      setIsNotification(true);
    }
    if (currentUrl.includes('/search?searchType=Professional')) {
      setProfessionalSearch(true);
    }
    if (currentUrl.includes('/jobs')) {
      setIsJobs(true);
    }
  }, []);
  const [signupModal, setSignupModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles['bottombar-wrapper']}>
      <div
        onClick={() => {
          navigate('/search?searchType=Professional');
          window.scrollTo(0, 0);
        }}
        className={`${styles['bottomBar-container-start']} ${
          !isJobs && professionalSearch ? styles['professional-active'] : ''
        }`}
      >
        {professionalSearch ? (
          <BottomBarProfImage fill="#e6e6e6" />
        ) : (
          <BottomBarProfImage fill="#666" />
        )}
        <p>Professionals</p>
      </div>

      <div
        className={classNames(
          !professionalSearch && !isJobs
            ? styles['dotted-line']
            : styles['invisible-line']
        )}
      />

      <div
        onClick={() => {
          navigate('/jobs');
          window.scrollTo(0, 0);
        }}
        className={`${styles['bottomBar-container-middleRight']} ${
          isJobs ? styles['jobs-active'] : ''
        }`}
      >
        {isJobs ? (
          <img
            src="/images/briefcase-active.svg"
            alt="jobsIcon"
            height={20}
            width={20}
            style={{ fill: '#000000' }}
          />
        ) : (
          <img
            src="/images/briefcase.svg"
            alt="jobsIcon"
            height={20}
            width={20}
          />
        )}
        <p>Jobs</p>
      </div>

      <div>
        <div
          onClick={() => {
            navigate('/dashboard');
            window.scrollTo(0, 0);
          }}
          className={`${styles['bottomBar-container-center']} ${
            !isOpen && isDashboard ? styles['dashboard-active'] : ''
          }`}
        >
          {isDashboard && !isOpen ? (
            <img
              src="/images/home-mobile-active.svg"
              alt="home button"
              height={20}
              width={20}
            />
          ) : (
            <img
              src="/images/home-mobile.svg"
              alt="home button"
              height={20}
              width={20}
            />
          )}
          <p>Home</p>
        </div>
      </div>
      <div
        onClick={() => {
          navigate('/events');
          window.scrollTo(0, 0);
        }}
        className={`${styles['bottomBar-container-start']} ${
          !isOpen && isEvents ? styles['events-active'] : ''
        }`}
      >
        {isEvents ? (
          <img
            src="/images/calender-outline-active.svg"
            alt="event button"
            height={20}
            width={20}
          />
        ) : (
          <img
            src="/images/CalendarOutline.svg"
            alt="event button"
            height={20}
            width={20}
          />
        )}
        <p>Event</p>
      </div>
      <div
        className={classNames(
          !isEvents && !isNotification
            ? styles['dotted-line']
            : styles['invisible-line']
        )}
      />
      <div
        onClick={() => {
          navigate('/notification');
          window.scrollTo(0, 0);
        }}
        className={`${styles['bottomBar-container-middleLeft']} ${
          !isOpen && isNotification ? styles['notification-active'] : ''
        }`}
      >
        {isNotification ? (
          <img
            src="/images/marketing-active.svg"
            alt="notification button"
            height={20}
            width={20}
          />
        ) : (
          <img
            src="/images/marketing.svg"
            alt="notification button"
            height={20}
            width={20}
          />
        )}

        <p>Update</p>
      </div>

      <JobsModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
}
