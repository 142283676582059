import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from "react";
import styles from './validation-message.module.css';

type Props = {
    children: ReactNode;
};

export const ValidationMessage = ({ children }: Props) => {
    return (
        <div className={styles.message}>
            {
                typeof children === "string" ?
                    <>
                        <FontAwesomeIcon icon={faInfoCircle} /> {children}
                    </> : children
            }
        </div>
    )
}