import axiosInstance from 'src/config/api';
import { ApiResponse } from './meta-data-interface';

export const getMenuData = async (
  isVerified = true,
  populateUser = false
): Promise<ApiResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/meta-data?pageSize=50&sortBy=name&sortOrder=asc&isVerified=${isVerified}${
        populateUser ? '&populate=_userId' : ''
      }`,
      {
        params: { populate: 'subMetaDatas' },
      }
    )
  ).data;
};

export const createMenuData = async (
  category: string,
  description: string
): Promise<ApiResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const payload = {
    name: category,
    description,
    type: 'OTHER',
    icon: {
      name: '1696248965338-301110984.png',
      originalName:
        'png-transparent-download-save-save-on-computer-upload-file-guardar-audio-controls-ui-icons-free-icon.png',
      mimetype: 'image/png',
      extension: '.png',
      destination: './uploads',
      path: '/api/v1/file/1696248965338-301110984.png',
      size: 6313,
    },
  };
  return (
    await axiosInstance.post(
      `${baseUrl}/api/v1/meta-data?isVerified=false`,
      payload
    )
  ).data;
};

export const updateMetaData = async (
  id: string,
  request: any
): Promise<any> => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  return (
    await axiosInstance.patch(`${baseUrl}/api/v1/meta-data/${id}`, request)
  ).data;
};
