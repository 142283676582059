import {
  faCheck,
  faCircleArrowDown,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styles from '../../events/events.layout.module.css';
import { handleInvite } from 'src/services/user-service/user-servicec';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import SignupModal from 'src/components/stepper/SignupModal';

export default function InviteProfessional() {
  const [emailId, setEmailId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailNotSent, setEmailNotSent] = useState(false);
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!token) {
      setSignupModal(true);
    } else {
      setEmailId(event.target.value);

      if (emailId.length > 0) {
        setEmailSent(false);
        setEmailNotSent(false);
      }
    }
  };

  const sendInviteMail = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    if (
      (event.key === 'Enter' || event.key === 'Return') &&
      emailId.includes('@')
    ) {
      try {
        await handleInvite(emailId);
        setEmailId('');
        setEmailSent(true);
      } catch (error) {
        setEmailId('');
        setEmailNotSent(true);
      }
    }
    setLoading(false);
  };

  const handleSendInviteMail = async () => {
    setLoading(true);
    if (emailId.includes('@')) {
      try {
        await handleInvite(emailId);
        setEmailId('');
        setEmailSent(true);
      } catch (error) {
        setEmailId('');
        setEmailNotSent(true);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className={styles['name-search-parent']}>
        {' '}
        <div
          className={classNames(
            styles['name-search-wrapper'],
            emailNotSent ? styles['inviteMailError'] : ''
          )}
        >
          <img
            src="/images/inviteIcon.png"
            alt="inviteIcon"
            className={styles['job-name-search-glass']}
          />
          <input
            type="text"
            value={emailId}
            onChange={handleEmailChange}
            placeholder="Send an E-mail Invitation"
            className={styles['job-name-input']}
            onKeyDown={sendInviteMail}
            enterKeyHint="go"
          />{' '}
          {window.innerWidth > 700 &&
            (loading ? (
              <Spinner animation="border" variant="secondary" size="sm" />
            ) : !loading && emailSent ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : !loading && emailNotSent ? (
              <FontAwesomeIcon icon={faCircleXmark} />
            ) : (
              <FontAwesomeIcon
                icon={faCircleArrowDown}
                rotation={270}
                className={styles['job-name-search-icon']}
                onClick={() => handleSendInviteMail()}
              />
            ))}
        </div>
      </div>
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
}
