import { FC, ReactNode, createContext, useState } from 'react';
import { getMessageForTwoMutualUser } from 'src/services/message-service/message-service';

interface messageType {
  user: object;
  message: object;
}

const initialState: messageType[] = [
  {
    user: { _id: '' },
    message: {},
  },
];

interface IMessageContext {
  selectedFriend: any;
  isMinimized: any;
  openChatWindow: (freind: any) => void;
  closeChatWindow: (freindId: any) => void;
  fetchMessageFromId: (id: any, user: any) => Promise<void>;
  toggleChat: (data: any) => void;
}

const MessageContext = createContext<IMessageContext>({
  selectedFriend: initialState,
  isMinimized: false,
  openChatWindow: (freind) => console.log(freind),
  closeChatWindow: (freindId) => console.log('close chat widow', freindId),
  fetchMessageFromId: async (id, user) => console.log(id, user),
  toggleChat: (data) => console.log(data),
});

const MessageProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [selectedFriend, setSelectedFriend] = useState<any[]>([]);
  const [isMinimized, setIsMinimized] = useState<string[]>([]);

  const openChatWindow = (friend: any) => {
    if (
      !selectedFriend.some(
        (prevFriend) => prevFriend?.user?._id === friend?.user?._id
      )
    ) {
      if (selectedFriend.length < 2) {
        setSelectedFriend((prev: any) => [...prev, friend]);
      } else {
        const updatedWindows = selectedFriend.slice(1);
        const updatedMinimized = isMinimized.filter((minimizedId) =>
          updatedWindows.find(
            (updatedWindow) => updatedWindow?.user?._id === minimizedId
          )
        );
        setIsMinimized(updatedMinimized);
        setSelectedFriend([...updatedWindows, friend]);
      }
    }
  };

  const closeChatWindow = (friendId: any) => {
    const filteredChatWindow = selectedFriend?.filter((data: any) => {
      return data?.user?._id !== friendId;
    });
    const newMinimized = [...isMinimized];
    const minimizedIndex = newMinimized.findIndex(
      (minimizedId) => minimizedId === friendId
    );
    if (minimizedIndex > -1) {
      newMinimized.splice(minimizedIndex, 1);
    }
    setIsMinimized([...newMinimized]);
    setSelectedFriend(filteredChatWindow);
  };

  const fetchMessageFromId = async (yourId: any, user: any) => {
    try {
      if (
        !selectedFriend.some(
          (prevFriend) => prevFriend?.user?._id === user?._id
        )
      ) {
        const fetchMessage = await getMessageForTwoMutualUser(user?.id, yourId);
        const selectedData = {
          user: user,
          message: fetchMessage?.data,
        };
        if (selectedFriend.length < 2) {
          setSelectedFriend((prev: any) => [...prev, selectedData]);
        } else {
          const updatedWindows = selectedFriend.slice(1);
          const updatedMinimized = isMinimized.filter((minimizedId) =>
            updatedWindows.find(
              (updatedWindow) => updatedWindow?.user?._id === minimizedId
            )
          );
          setIsMinimized(updatedMinimized);
          setSelectedFriend([...updatedWindows, selectedData]);
        }
      } else {
        toggleChat({ user });
      }
    } catch (error) {
      console.error('unable to fetch the messages', error);
    }
  };

  const toggleChat = (data: any) => {
    // data?.user?._id
    const newMinimized = [...isMinimized];
    const minimizedIndex = newMinimized.findIndex(
      (minimizedId) => minimizedId === data?.user?._id
    );
    if (minimizedIndex > -1) {
      newMinimized.splice(minimizedIndex, 1);
      setIsMinimized([...newMinimized]);
    } else {
      setIsMinimized([...newMinimized, data?.user?._id]);
    }
  };

  return (
    <MessageContext.Provider
      value={{
        selectedFriend,
        isMinimized,
        openChatWindow,
        closeChatWindow,
        fetchMessageFromId,
        toggleChat,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export { MessageContext, MessageProvider };
