import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { HiringLottie } from '../lotties/hiring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './jobs.module.css';

function JobsModal({ isOpen, setIsOpen }) {
  return (
    <Modal
      isOpen={isOpen}
      centered
      size="md"
      className={styles['jobModalParent']}
    >
      <ModalBody className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div className={styles.textContainer}>
            <img
              src="/images/jobs-logo.svg"
              alt="jobslogo"
              className={styles['jobs-logo']}
            />
            &nbsp; Recruitment (Jobs)
          </div>
          <div>
            <FontAwesomeIcon
              icon={faXmark}
              className={styles.closeIcon}
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>

        <HiringLottie height={window.innerWidth > 700 ? 300 : 180} />

        <div className={styles['modal-body-text']}>
          While we build, grab a coffee ☕ and dream about your future career
          adventures.
        </div>
      </ModalBody>
    </Modal>
  );
}

export default JobsModal;
