import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './file-list.module.css';

type Props = {
  files: Array<File>;
  onClose: (index: number) => void;
};

const getFileIcon = (file: File) => {
  const extension = (file.name.split('.').pop() || '').toLowerCase();

  if (['xls', 'xlsx'].includes(extension)) {
    return '/images/excelIcon.png';
  } else if (['pdf'].includes(extension)) {
    return '/images/pdfIcon.png';
  } else if (['doc', 'docx'].includes(extension)) {
    return '/images/wordIcon.png';
  } else if (['ppt', 'pptx'].includes(extension)) {
    return '/images/pptIcon.png';
  } else if (['mp4', 'avi', 'mov', 'wmv'].includes(extension)) {
    return '/images/video-icon.svg'; // Path to your video file icon
  } else {
    return '/images/imageIcon.png';
  }
};

export const FileList = ({ files, onClose }: Props) => {
  return (
    <div className={styles.filesContainer}>
      {files.map((file, index) => {
        const fileExtension = (file.name.split('.').pop() || '').toLowerCase();
        const fileNameWithoutExtension = file.name.replace(
          `.${fileExtension}`,
          ''
        );
        const fileName =
          fileNameWithoutExtension.length > 10
            ? `${fileNameWithoutExtension.slice(0, 10) + '..'}`
            : fileNameWithoutExtension;

        return (
          <span className={styles.fileContainer}>
            <img
              className={styles.fileIcon}
              src={getFileIcon(file)}
              alt={file.name}
            />{' '}
            <span className={styles.fileName}>{fileName}</span>
            <FontAwesomeIcon
              className={styles.closeIcon}
              onClick={() => onClose(index)}
              color="#dc3545"
              icon={faClose}
            />
          </span>
        );
      })}
    </div>
  );
};
