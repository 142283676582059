import { INotification } from 'src/services/notification-service/notification-interface';
import { IUser } from 'src/services/user-service/user-service.interface';

export interface IFormattedNotifications {
  [key: string]: INotification[];
}

export const formatNotifications = (
  notifications: INotification[]
): IFormattedNotifications => {
  const groupedNoti: IFormattedNotifications = {};

  notifications.forEach((noti) => {
    const publishedDate = new Date(noti.publishedOn);
    const day = publishedDate.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    if (!groupedNoti[day]) {
      groupedNoti[day] = [];
    }

    groupedNoti[day].push(noti);
  });

  return groupedNoti;
};

export const margeFormattedNotifications = (
  formattedNotifications: IFormattedNotifications,
  formatRes: IFormattedNotifications
): IFormattedNotifications => {
  const mergedNotifications: IFormattedNotifications = {
    ...formattedNotifications,
  };

  Object.keys(formatRes).map((date) => {
    if (mergedNotifications[date]) {
      mergedNotifications[date] = [
        ...mergedNotifications[date],
        ...formatRes[date],
      ];
    } else {
      mergedNotifications[date] = formatRes[date];
    }
  });
  return mergedNotifications;
};

export const getPinFromNotification = (
  data: INotification,
  following: IUser[]
) => {
  const postFollowings = following?.filter(
    ({ type }: any) => type === 'NOTIFICATION'
  );
  const pinId = postFollowings.find(
    (follow: any) => follow.postId === data?.id
  )?._id;
  return pinId || '';
};
