export const checkIfRequiredFieldsEntered = (
  formData: any,
  isEmailVerified: boolean
): boolean => {
  return (
    !formData.name || !formData.email || !formData.password || !isEmailVerified
  );
};

export const validateForm = (formData: any) => {
  const errors: any = {};
  if (!formData.name) {
    errors.name = 'Please input the name';
  }
  if (!formData.email) {
    errors.email = 'Please input the email';
  }

  if (!formData.password) {
    errors.password = 'Please input the password';
  }

  return errors;
};
