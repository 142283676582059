import Logo from 'src/components/logo';
import styles from './home.module.css';
import TextImage from 'src/components/text-image';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AppPopUp from 'src/components/bottombar/AppPopUp';
import DashboardLogin from 'src/components/login-btns/DashboardLogin';
import { Button } from 'reactstrap';
import EventImage from 'src/components/utils/EventImage';
import NotiImage from 'src/components/utils/NotificationImage';
import UpdateImage from 'src/components/utils/UpdateImage';
import HomeImage from 'src/components/utils/HomeImage';
import BottomBarProfImage from 'src/components/utils/BottomBarProfImage';

export default function Home() {
  const navigate = useNavigate();
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [headerVisible, setHeaderVisible] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (prevScrollPos > currentScrollPos) {
      // Scrolling up
      setHeaderVisible(true);
    } else {
      // Scrolling down
      setHeaderVisible(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const imageUrl = `${process.env.PUBLIC_URL}/images/wc_logoMain.svg`;

  return (
    <>
      <Helmet>
        <title>White Collar Professional | Community Platform</title>
        <meta
          name="description"
          content="WCP is a place to gain & share knowledge, ask questions, and connect with professionals who contribute unique insights and quality answers. One-stop solution for all your doubts from CA, CS, CWA & Advocates.
          "
        />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content="20" />
        <meta property="og:image:height" content="25" />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className={styles.parentContainer}>
        <div
          className={`${styles.homeHeader} ${
            !headerVisible ? styles.hiddenHeader : ''
          }`}
        >
          <div className={styles['navbar-wrapper']}>
            <Logo src="/images/logo.svg" color="white" />

            <div className={styles['home-nav-btn-wrapper']}>
              <div className={styles['home-nav-btn']}>
                <Button color="transparent">
                  <Link to="/dashboard">
                    <HomeImage fill={'#fff'} width="20" />
                  </Link>
                </Button>
              </div>
              {window.innerWidth > 700 && (
                <>
                  <div className={styles['home-nav-btn']}>
                    <Button color="transparent">
                      <Link to="/search?searchType=Professional">
                        <BottomBarProfImage fill="#e6e6e6" />
                      </Link>
                    </Button>
                  </div>
                  <div className={styles['home-nav-btn']}>
                    <Button color="transparent">
                      <Link to="/events">
                        <EventImage fill="#fff" height={18} width={18} />
                      </Link>
                    </Button>
                  </div>
                  <div className={styles['home-nav-btn']}>
                    <Button color="transparent">
                      <Link to="/notification">
                        <NotiImage fill={'#fff'} />
                      </Link>
                    </Button>
                  </div>
                  <div className={styles['home-nav-btn']}>
                    <Button color="transparent">
                      <Link to="/jobs">
                        <UpdateImage fill="#fff" />
                      </Link>
                    </Button>
                  </div>
                </>
              )}
              <div className={styles.loginbtn_wrapper}>
                <button
                  className={styles.getStartedBtn}
                  onClick={() => navigate('/newsignup')}
                >
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['home-login-img-wrapper']}>
          <div className={styles['home-login-text-wrapper']}>
            <div className={styles['home-text-wrapper']}>
              <div className={styles['home-text-first']}>Let's Connect </div>
              <div className={styles['home-text-second']}>the dots . . .</div>
              <div className={styles['home-text-third']}>
                (Knowledge Sharing and Professional Networking Platform)
              </div>
            </div>
            <div className={styles['login-wrapper']}>
              <DashboardLogin />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <div className={styles.connectProfImgConatiner}>
              <img
                src="/images/loginGlobe.svg"
                alt="connetProfessionalGlobe - White Collar Professional"
                className={styles.connectProfImg}
              />
            </div>
            <div className={styles['images-logo-wrapper']}>
              <img
                src="/images/ca-home-logo.png"
                alt="CA logo - White Collar Professional"
              />
              <img
                src="/images/cs-home-logo.svg"
                alt="CS logo - White Collar Professional"
              />
              <img
                src="/images/cma-home-logo.png"
                alt="CMA logo - White Collar Professional"
                style={{ marginBottom: '2px' }}
              />
              <img
                src="/images/adv-home-logo.svg"
                alt="Advocate logo - White Collar Professional"
              />
            </div>
          </div>
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.container}>
            {/* <h1 className={styles.titleText}>
              Multiple Institutions . One Solution
            </h1> */}

            {/* <div className={styles.loginSignupWrapper}>
              <LoginBtns
                signin="Login"
                textDecoration
                color
                fontSize
                fontWeight
              />

              <button
                className={styles.loginSignupButton}
                onClick={() => navigate('/newsignup')}
              >
                Sign Up
              </button>
            </div> */}

            {/* <h3 className={styles.whatweDo}>WHAT WE DO ?</h3> */}
            {/* <h2 className={styles.connectProfessionals}>
              We connect Professionals
            </h2> */}

            <TextImage
              title="Post & Publish Work"
              desc={
                <span>
                  A space for{' '}
                  <span className={styles['textimage-desc-ca']}>
                    Chartered Accountants
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-cs']}>
                    Company Secretaries
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-cwa']}>
                    Cost and Work Accountants
                  </span>
                  , and{' '}
                  <span className={styles['textimage-desc-adv']}>
                    Advocates
                  </span>{' '}
                  to showcase their knowledge and skills by{' '}
                  <span className={styles['textimage-desc-bold']}>
                    {' '}
                    publishing their research
                  </span>{' '}
                  ,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    articles
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    and other works
                  </span>{' '}
                  to a wider audience. Users can create profiles and share their
                  work within the WCP Community Platform. As well as,
                  Professionals can connect with others in their field,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    gain visibility
                  </span>{' '}
                  for their work, and{' '}
                  <span className={styles['textimage-desc-bold']}>
                    grow their professional network
                  </span>
                  .
                </span>
              }
              flexDirection="row-reverse"
              imageComp={
                <img
                  src="/images/home2.svg"
                  alt="Post & Publish Work logo - White Collar Professional"
                />
              }
            />

            <TextImage
              title="Ask and Answer"
              desc={
                <span>
                  White Collar Professional provides a platform where{' '}
                  <span className={styles['textimage-desc-bold']}>
                    knowledge seekers and sceptics
                  </span>{' '}
                  can post questions and receive answers from other
                  professionals in the domain of{' '}
                  <span className={styles['textimage-desc-ca']}>
                    Chartered Accountant
                  </span>
                  ,
                  <span className={styles['textimage-desc-cs']}>
                    {' '}
                    Company Secretary
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-cwa']}>
                    Cost & Work Accountant
                  </span>
                  , and{' '}
                  <span className={styles['textimage-desc-adv']}>
                    Advocates
                  </span>
                  . Users can also search for existing answers or post new
                  questions,
                  <span className={styles['textimage-desc-bold']}>
                    {' '}
                    up-vote
                  </span>{' '}
                  for the best answers,
                  <span className={styles['textimage-desc-bold']}>
                    tag questions
                  </span>{' '}
                  ,
                  <span className={styles['textimage-desc-bold']}>
                    comment on answers
                  </span>
                  , and{' '}
                  <span className={styles['textimage-desc-bold']}>follow</span>{' '}
                  their specific interests.
                </span>
              }
              flexDirection="row"
              imageComp={
                <img
                  src="/images/home3.svg"
                  alt="Ask and Answer logo - White Collar Professional"
                />
              }
            />

            <TextImage
              title="Recruitment (Jobs)"
              desc={
                <span>
                  A perfect destination for{' '}
                  <span className={styles['textimage-desc-bold']}>hiring</span>{' '}
                  from a target user base of professionals, White Collar
                  Professional offers a dedicated space for{' '}
                  <span className={styles['textimage-desc-bold']}>
                    job postings
                  </span>
                  . Recruiters can{' '}
                  <span className={styles['textimage-desc-bold']}>
                    advertise vacancies
                  </span>{' '}
                  within their organizations, catering to professionals at
                  various stages of their careers. Our platform facilitates
                  <span className={styles['textimage-desc-bold']}>
                    {' '}
                    direct connections
                  </span>{' '}
                  between{' '}
                  <span className={styles['textimage-desc-bold']}>
                    {' '}
                    job seekers
                  </span>{' '}
                  and{' '}
                  <span className={styles['textimage-desc-bold']}>
                    {' '}
                    hiring managers
                  </span>{' '}
                  , empowering them to explore opportunities and take on
                  long/short assignments of varying lengths.
                </span>
              }
              flexDirection="row-reverse"
              imageComp={
                <img
                  src="/images/recruitmentHome.png"
                  alt="Recruitment - White Collar Professional"
                />
              }
            />

            <TextImage
              title="Track the feed & Request"
              desc={
                <span>
                  Professionals can{' '}
                  <span className={styles['textimage-desc-bold']}>request</span>{' '}
                  other professionals holding expertise in the specific domain,
                  or sector to answer/comment on{' '}
                  <span className={styles['textimage-desc-bold']}>
                    specific questions or posts
                  </span>
                  , ensuring that the best possible answer is provided.
                  Additionally, at the White Collar Professional community
                  platform users can{' '}
                  <span className={styles['textimage-desc-bold']}>
                    pin/unpin
                  </span>{' '}
                  a specific post or question to keep it highlighted in the
                  user’s profile, making it easy for them to get back to it
                  again later.
                </span>
              }
              flexDirection="row"
              imageComp={
                <img
                  src="/images/home4.svg"
                  alt="Track the feed & Request logo - White Collar Professional"
                />
              }
            />

            <TextImage
              title="Create Network"
              desc={
                <span>
                  The WCP Community platform is designed to help
                  <span className={styles['textimage-desc-ca']}>
                    {' '}
                    Chartered Accountants
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-cs']}>
                    Company Secretaries
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-cwa']}>
                    Cost & Work Accountants
                  </span>
                  , and{' '}
                  <span className={styles['textimage-desc-adv']}>
                    Advocates
                  </span>{' '}
                  to build a{' '}
                  <span className={styles['textimage-desc-bold']}>
                    wider network
                  </span>{' '}
                  of connections, regardless of where they are located. It
                  allows them to{' '}
                  <span className={styles['textimage-desc-bold']}>
                    exchange ideas
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    learn from others
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    and build relationships
                  </span>{' '}
                  with other professionals. It’s a great way for professionals
                  to expand their network and grow their career.
                </span>
              }
              flexDirection="row-reverse"
              imageComp={
                <img
                  src="/images/home5.svg"
                  alt="Create Network logo - White Collar Professional"
                />
              }
            />

            <TextImage
              title="Recent News & Events"
              desc={
                <span>
                  A notification center with links to all the{' '}
                  <span className={styles['textimage-desc-bold']}>
                    recent changes
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    news, and updates
                  </span>{' '}
                  with respect to the interests of professionals, designed to
                  keep users updated. Also, WCP Community platform serves the
                  complete list of{' '}
                  <span className={styles['textimage-desc-bold']}>
                    upcoming events
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    organized by the Institute
                  </span>
                  ,{' '}
                  <span className={styles['textimage-desc-bold']}>
                    professional community
                  </span>
                  , or any organization. The notification center and Event panel
                  act as a central hub for all the information that users may
                  find relevant.
                </span>
              }
              flexDirection="row"
              imageComp={
                <img
                  src="/images/home6.svg"
                  alt="Recent News & Events logo - White Collar Professional"
                />
              }
            />

            <TextImage
              title="Build Trust"
              desc={
                <span>
                  The paramount feature of the White Collar Professional
                  Community platform is the authenticity of professionals, who
                  are confirmed as experts using{' '}
                  <span className={styles['textimage-desc-bold']}>
                    Aadhaar (UIDAI)
                  </span>{' '}
                  OTP OKYC and members of the relevant regulatory authorities
                  and/or councils. This ensures that the information given is
                  correct, conscientiously{' '}
                  <span className={styles['textimage-desc-bold']}>
                    verified
                  </span>
                  , and comes from{' '}
                  <span className={styles['textimage-desc-bold']}>
                    reliable sources
                  </span>
                  . <br />
                  <br />{' '}
                  <span className={styles['textimage-desc-bold']}>
                    Let's connect, collaborate, and elevate the professional's
                    journey.
                  </span>
                </span>
              }
              flexDirection="row-reverse"
              imageComp={
                <img
                  src="/images/home7.svg"
                  alt="Build Trust logo - White Collar Professional"
                />
              }
            />

            {/* <div className={styles.profAuthWrapper}>
              <img
                src="/images/aadhaar_logo.png"
                alt="aadhaar_logo - White Collar Professional"
              />
              <img
                src="/images/signzy-logo-black.svg"
                alt="signzy_logo - White Collar Professional"
              />
            </div> */}

            {/* <div className={styles.carouselDiv}>
              <ImageCarousel />
            </div> */}
            {/* <div className={styles['iframe-wrapper']}>
              <iframe
                className={styles['youtube-Iframe']}
                src="https://www.youtube.com/embed/-62Tcw2Eh7Y?si=GfBF6WwIZpuxPV_b&amp;autoplay=1&mute=1;controls=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div> */}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.aboutUsWrapper}>
            <h3 className={styles.aboutUsTitle}>About Us</h3>
            <body className={styles.aboutUsDesc}>
              "White Collar Professional – a Community Platform where group of
              professionals, coming together to set new milestones by providing
              services with a new perspective. We intend to create a difference
              in our society and believe that every client is unique and
              deserves a personalized experience. <br /> <hr />
              WCP Platform – which interacts and guarantees authenticity and
              validity of the professionals to the users of this platform.
              Currently, we connect professionals in the domain of Chartered
              Accountant , Company Secretary , Cost and Work Accountant and
              Advocate ."
            </body>
          </div>
          <div className={styles['help-follow-footer']}>
            <div className={styles.helpLegal}>
              <div className={styles.helpGuidelines}>
                <h3 className={styles.helpGuidelinesTitle}>
                  Help & Guidelines
                </h3>
                <div className={styles.helpGuidlinesWrapper}>
                  <Link to="/technicalsupport" target="_blank">
                    Technical Support
                  </Link>

                  <a
                    href="https://www.whitecollarprofessional.com/institutional-guidelines/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {isMobile ? 'Inst. Guidelines' : 'Institutional Guidance'}
                  </a>
                  <a
                    href="https://www.whitecollarprofessional.com/professional-community-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {isMobile ? 'Community Policy' : 'Proff. Community Policy'}
                  </a>
                </div>
              </div>

              <div className={styles.policy}>
                <h3 className={styles.legalPolicyTittle}>Legal Policy</h3>
                <div className={styles.legalPolicyWrapper}>
                  <a
                    href="https://www.whitecollarprofessional.com/cookie-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cookie Policy
                  </a>
                  <a
                    href="https://www.whitecollarprofessional.com/privacy-policy-2/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="https://www.whitecollarprofessional.com/user-agreement/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    User Agreement
                  </a>

                  <a
                    href="https://www.whitecollarprofessional.com/copyright-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Copyright Policy
                  </a>
                  <a
                    href="https://www.whitecollarprofessional.com/branding-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Branding Policy
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.followContainer}>
              <div className={styles['follow-title']}>Follow us on </div>
              <div>
                <a
                  href="http://www.linkedin.com/company/white-collar-professional/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-linkedin-square" />
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/wcp_community"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-twitter" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/whitecollarprofessional/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook-square" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/white_collar_professional/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerCopyright}>
          <div className={styles.aboutUsSocial}>
            {isMobile ? (
              <>Copyright © 2024 - Taaran Industries Private Limited</>
            ) : (
              <>
                Copyright © 2024{' '}
                <h1
                  className={styles.aboutUsSocial}
                  style={{ display: 'inline-flex' }}
                >
                  White Collar Professional
                </h1>{' '}
                - Taaran Industries Private Limited
              </>
            )}
          </div>
        </div>
        {isMobile && <AppPopUp bottom="0" />}
      </div>
    </>
  );
}
