import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { SubmittedJobData, formatToIndianCurrency } from './HiringJobs';
import styles from './hiringJobs.module.css';
import { formatDateStr } from 'src/utils/dateUtil';
import { resolveBEAsset } from 'src/utils/urls';
import { Badge } from 'reactstrap';
import classNames from 'classnames';

interface JobDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  job: SubmittedJobData | null;
}

const JobDetailsModal: React.FC<JobDetailsModalProps> = ({
  isOpen,
  onClose,
  job,
}) => {
  const [skills, setSkills] = useState<string[]>([]);

  useEffect(() => {
    if (job?.jobSkills) {
      const skillsArray: string[] = job.jobSkills.split(', ');
      setSkills(skillsArray);
    }
  }, []);
  if (!job) return null;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered
      className={styles['job-details-modal']}
    >
      <Modal.Header closeButton>
        <Modal.Title className={styles['job-details-modal-header-wrapper']}>
          <img
            src={
              job?.jobImage
                ? resolveBEAsset(job?.jobImage)
                : '/images/eventsDefaultImage.png'
            }
            alt="job logo - Team WCP"
          />
          <span>{job.jobName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles['job-details']}>
          {job.jobId && (
            <div className={styles['job-details-modal-key-value-wrapper']}>
              <strong className={styles['job-details-modal-key']}>
                Job Id
              </strong>
              :{' '}
              <div className={styles['job-details-modal-value']}>
                {job.jobId}
              </div>
            </div>
          )}
          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>
              Job Qualification
            </strong>
            :
            <div className={styles['job-details-modal-value']}>
              {job.jobQualification}
            </div>
          </div>
          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>
              Job Location
            </strong>
            :{' '}
            <div className={styles['job-details-modal-value']}>
              {job.jobLocation}
            </div>
          </div>
          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>
              Designation
            </strong>
            :{' '}
            <div className={styles['job-details-modal-value']}>
              {job.jobOpening}
            </div>
          </div>
          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>
              Experience
            </strong>
            :{' '}
            <div className={styles['job-details-modal-value']}>
              {job.jobExperience}
            </div>
          </div>
          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>Amount</strong>:
            <div className={styles['job-details-modal-value']}>
              {' '}
              {job.jobAmount
                ? `₹ ${formatToIndianCurrency(job.jobAmount)}`
                : 'Not Disclosed'}
            </div>
          </div>
          {job?.jobSkills?.length > 0 && (
            <div className={styles['job-details-modal-key-value-wrapper']}>
              <strong className={styles['job-details-modal-key']}>
                Skills
              </strong>
              :{' '}
              <div className={styles['job-details-modal-value']}>
                {/* {job.jobSkills} */}
                <div className={styles['job-details-skills-wrapper']}>
                  {skills.map((skill: any, index) => (
                    <Badge
                      pill
                      key={index}
                      bg="secondary"
                      className={styles['job-details-skills-badge']}
                    >
                      {skill}{' '}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>Type</strong>:{' '}
            <div className={styles['job-details-modal-value']}>
              {job.jobType}
            </div>
          </div>
          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>Tenure</strong>:{' '}
            <div className={styles['job-details-modal-value']}>
              {job.jobTenure === 'Permanent Role'
                ? job.jobTenure
                : job.jobTenure === '1'
                ? `${job.jobTenure} month`
                : `${job.jobTenure} months`}
            </div>
          </div>

          <div className={styles['job-details-modal-key-value-wrapper']}>
            <strong className={styles['job-details-modal-key']}>
              Deadline
            </strong>
            :{' '}
            <div className={styles['job-details-modal-value']}>
              {formatDateStr(job.jobDeadLine)}
            </div>
          </div>
          {job?.jobUrl?.length > 0 && (
            <div className={styles['job-details-modal-key-value-wrapper']}>
              <strong className={styles['job-details-modal-key']}>
                Application Link
              </strong>
              :{' '}
              <div className={styles['job-details-modal-value']}>
                <a href={job?.jobUrl} target="_blank" rel="noreferrer">
                  Click Here to Apply
                </a>
              </div>
            </div>
          )}

          {job.jobContactName && (
            <div className={styles['job-details-modal-key-value-wrapper']}>
              <strong className={styles['job-details-modal-key']}>
                Contact Name
              </strong>
              :{' '}
              <div className={styles['job-details-modal-value']}>
                {job.jobContactName}
              </div>
            </div>
          )}
          {job.jobContactNumber && (
            <div className={styles['job-details-modal-key-value-wrapper']}>
              <strong className={styles['job-details-modal-key']}>
                Contact Number
              </strong>
              :{' '}
              <div className={styles['job-details-modal-value']}>
                {job.jobContactNumber}
              </div>
            </div>
          )}
          {job.jobContactEmail && (
            <div className={styles['job-details-modal-key-value-wrapper']}>
              <strong className={styles['job-details-modal-key']}>
                Contact Email
              </strong>
              :{' '}
              <div className={styles['job-details-modal-value']}>
                {job.jobContactEmail}
              </div>
            </div>
          )}

          {job.content && (
            <div className={styles['job-details-modal-key-value-wrapper']}>
              <strong className={styles['job-details-modal-key']}>
                Description
              </strong>
              :
            </div>
          )}
          {job.content && (
            <div
              className={classNames(
                styles['job-details-modal-value'],
                styles['job-desc-value']
              )}
            >
              {job.content}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JobDetailsModal;
