import { ReactNode, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { dataUrlToFile } from 'src/utils/urls';
import styles from './avatar-upload.module.css';

type IProps = {
  children: ReactNode;
  onProcessed: (file: File) => void;
};

const resizeImage = (file: File, targetSizeKB: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

        const maxSize = targetSizeKB * 1024;
        let width = img.width;
        let height = img.height;

        // maintaining aspect ratio
        const ratio = width / height;
        if (width > height) {
          width = Math.min(Math.sqrt(maxSize / ratio), img.width);
          height = width / ratio;
        } else {
          height = Math.min(Math.sqrt(maxSize * ratio), img.height);
          width = height * ratio;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        const resizedDataUrl = canvas.toDataURL(file.type);
        resolve(resizedDataUrl);
      };
      img.onerror = (error) => {
        reject(error);
      };
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
export const AvatarUpload = ({ children, onProcessed }: IProps) => {
  const [scale, setScale] = useState(50);
  const [dataUrl, setDataUrl] = useState<string>();
  const editor = useRef<AvatarEditor>(null);
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      getDataUrl(selectedFile);
      setShowModal(true);
    }
  };

  const onFileProcessed = async (url: string) => {
    onProcessed(await dataUrlToFile(url));
    setShowModal(false);
  };

  const getDataUrl = async (file: File) => {
    const resizedDataUrl = await resizeImage(file, 100);
    setDataUrl(resizedDataUrl);
  };

  const onApply = () => {
    if (editor) {
      const dataUrl = editor.current?.getImage().toDataURL();
      if (dataUrl) {
        onFileProcessed(dataUrl);
      }
    }
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={onFileUpload}
      />
      {<span onClick={() => fileInputRef?.current?.click()}>{children}</span>}
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal((prevShowModal) => !prevShowModal)}
        className={styles['crop-image-modal']}
      >
        <ModalHeader
          toggle={() => setShowModal((prevShowModal) => !prevShowModal)}
        >
          Crop Image
        </ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          {dataUrl && (
            <>
              <div className={styles['user-edit-image-wrapper']}>
                <AvatarEditor
                  ref={editor}
                  style={{
                    padding: '24px',
                  }}
                  height={350}
                  width={350}
                  scale={scale / 100}
                  image={dataUrl}
                  borderRadius={1000}
                />
              </div>
              <Input
                type="range"
                value={scale}
                step={1}
                max={500}
                min={50}
                onChange={(e) => setScale(parseInt(e.target.value || '1'))}
                className={styles['crop-img-scroll']}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            className={styles['crop-img-submit-btn']}
            onClick={onApply}
          >
            Apply
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
