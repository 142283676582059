import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import LoginBtns from '../login-btns';
import styles from './signupModal.module.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
type Props = {
  signupModal: any;
  setSignupModal: any;
};

const SignupModal = ({ signupModal, setSignupModal }: Props) => {
  const navigate = useNavigate();

  const baseSignUp = () => {
    navigate('/signup');
  };
  const newSignUp = () => {
    navigate('/newsignup');
  };

  return (
    <>
      <Modal
        isOpen={signupModal}
        centered
        toggle={() => setSignupModal(false)}
        className={styles['signup-modal']}
      >
        <ModalHeader className={styles['signup-modal-header']}>
          <img src="/images/logo copy.svg" alt="hi" />
        </ModalHeader>
        <ModalBody>
          <div className={styles['header-subtitle']}>
            {window.innerWidth <= 700 ? (
              <span>
                Welcome ! Just a step away from joining <br />
                WCP-Community Platform.
              </span>
            ) : (
              <span>
                Welcome ! Just a step away from joining WCP-Community Platform.
              </span>
            )}
          </div>
          <div className={styles['btn-wrapper']}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src="/images/signupGoogle.png"
                alt="googleSigninLogo"
                className={styles['signinGoogle-img']}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_API_HOST}/api/v1/auth/google`,
                    '_self'
                  )
                }
              />
            </div>{' '}
            <div className={styles['or-wrapper']}>
              <div
                style={{
                  flexGrow: '1',
                  height: '1px',
                  backgroundColor: 'rgb(219, 219, 219)',
                }}
              ></div>
              <div
                style={{
                  padding: '0 10px',
                  fontSize: '10px',
                  fontFamily: 'Roboto',
                }}
              >
                OR
              </div>
              <div
                style={{
                  flexGrow: '1',
                  height: '1px',
                  backgroundColor: 'rgb(219, 219, 219)',
                }}
              ></div>
            </div>
            <div>
              <span
                onClick={() => newSignUp()}
                className={styles['create-account']}
              >
                Create Account
              </span>
            </div>
          </div>
          <div className={styles['alreadylogin-wrapper']}>
            Already Registered ?
            <LoginBtns
              signin="Login"
              textDecoration="underline"
              color="blue"
              fontSize={window.innerWidth <= 700 ? '14px' : '18px'}
              fontWeight
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SignupModal;
