import Lottie from 'react-lottie';
import loadingJson from './success.json';

type Props = {
    height?: number
}

export const SuccessLottie = ({ height }: Props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingJson
    };

    return (
        <div>
            <Lottie
                options={defaultOptions}
                height={height}
            />
        </div>
    );
}