import { IMetaData } from 'src/services/meta-data-service/meta-data-interface';
import { IAttachment } from 'src/services/post-service/post-service.interface';
import { resolveBEAsset } from 'src/utils/urls';

export interface IOptions {
  value: string;
  label: string;
  _id?: string;
}

export const formatMenuToOptions = (menu: IMetaData[]): IOptions[] => {
  const options: IOptions[] = [];

  menu.forEach((metaData) => {
    options.push({
      label: metaData.name,
      value: JSON.stringify({ metaDataId: metaData._id }),
      _id: metaData._id,
    });
    if (metaData.subMetaDatas.length) {
      metaData.subMetaDatas.forEach((subMetaData) =>
        options.push({
          label: subMetaData.name,
          value: JSON.stringify({ subMetaDataId: subMetaData._id }),
          _id: subMetaData._id,
        })
      );
    }
  });

  return options;
};

export const formatExpertiseToOptions = (expertise: Array<any>, menu: IOptions[]): IOptions[] => {
  const options: IOptions[] = [];

  expertise.forEach((eItem) => {
    const option = menu.find((item) => item._id === eItem.metaDataId || item._id === eItem.subMetaDataId)
    if (option) {
      options.push(option)
    }
  })

  return options;
};


export const attachmentToFileMap = async (attachments: IAttachment[]) => {
  const files: Array<File> = [];
  for (const attachment of attachments) {
    let response = await fetch(resolveBEAsset(attachment.path), { headers: { "Cache-Control": 'no-cache' } });
    let data = await response.blob();
    const file = new File([data], attachment.originalName, { type: attachment.mimetype })

    files.push(file)
  }

  return files;
}