import { Input } from 'reactstrap';
import { IUser } from 'src/services/user-service/user-service.interface';
import { resolveBEAsset } from 'src/utils/urls';
import AvatarRow from '../avatar-row/avatar-row';
import styles from './request.module.css';

type Props = {
  users: Array<IUser>;
  selectedUsers: Array<IUser>;
  onChange: (users: Array<IUser>) => void;
};

export const UserList = ({ users, selectedUsers, onChange }: Props) => {
  const unselectedUsers = users; //users.filter(({ _id }) => {
  // return !selectedUsers.find((user) => user.id === _id)
  // })

  return (
    <>
      {selectedUsers.length > 0 && (
        <div className={styles.avatarList}>
          <div className={styles.avatarImage}>
            {selectedUsers.slice(0, 4).map((data, index) => {
              return (
                <>
                  <img
                    style={{ marginRight: '2px' }}
                    src={
                      data?.picture?.path
                        ? resolveBEAsset(data?.picture?.path || '')
                        : '/images/defaultProfilePic.svg'
                    }
                    alt="userDefaltImage"
                  />
                  {index === 3 && selectedUsers.length > 4 && (
                    <span className={styles.avatarPlus}>+</span>
                  )}
                </>
              );
            })}
          </div>
          <div className={styles.avatarCount}>{selectedUsers.length || 0}</div>
        </div>
      )}
      <div className={styles.userListContainer}>
        {/* {
          selectedUsers.map((user) => {
            return (
              <div className={styles.userContainer} key={user._id}>
                <div className={styles.userSelect}>
                  <Input checked={true} type="checkbox" onClick={(e) => {
                    const updatedSelectedUsers = selectedUsers.filter(({ _id }) => _id !== user._id)
                    onChange(updatedSelectedUsers)
                  }} />
                </div>
                <div className={styles.userSelect}>
                  <AvatarRow showLocation showMembership data={user} />
                </div>
              </div>
            )
          })
        } */}
        {unselectedUsers.map((user) => {
          const isSelected = !!selectedUsers.find(
            ({ _id }) => _id === user._id
          );
          return (
            <div className={styles.userContainer} key={user._id}>
              <div className={styles.userSelect}>
                <Input
                  checked={isSelected}
                  type="checkbox"
                  onClick={(e: any) => {
                    const updatedSelectedUsers = isSelected
                      ? selectedUsers.filter(({ _id }) => _id !== user._id)
                      : [...selectedUsers, user];
                    onChange(updatedSelectedUsers);
                  }}
                />
              </div>
              <div className={styles.userSelect}>
                <AvatarRow showMembership data={user} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
