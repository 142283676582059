import { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import CreatePost from 'src/components/create-post';
import { IPost } from 'src/services/post-service/post-service.interface';
import { attchmentList } from '../common';
import styles from './dashboard.module.css';
import SignupModal from '../stepper/SignupModal';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import { resolveBEAsset } from 'src/utils/urls';

const DashboardSearch = ({
  setRefresh,
}: {
  setRefresh: (post: IPost) => void;
}) => {
  const [query, setQuery] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const token = localStorage.getItem('token');

  const isLoggedIn = () => {
    if (token) {
      setModalShow(true);
    } else {
      setSignupModal(true);
    }
  };
  const id = localStorage.getItem('id');
  const [userPicture, setUserPicture] = useState('');

  const getUserDetails = async (userId: string) => {
    const userRes = await fetchUserDetails(userId);
    setUserPicture(userRes?.picture?.path);
  };
  useEffect(() => {
    getUserDetails(id || '');
  }, []);
  return (
    <div className={styles['dashboard-search']}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '75px',
          marginTop: '20px',
        }}
      >
        <div className={styles['searchInput-wrapper']}>
          <img
            src={
              userPicture
                ? resolveBEAsset(userPicture || '')
                : '/images/defaultProfilePic.svg'
            }
            alt="user_Image"
            className={styles['userPicture']}
          />
          <Input
            type="text"
            className={styles['dashboard-search-input']}
            placeholder="Type your Question / Publish Work"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onClick={() => isLoggedIn()}
          />
        </div>
      </div>
      <div className={styles['dashboard-search-attachment-list']}>
        {attchmentList.map((item, idx) => (
          <Button
            key={`Attachment_${idx}`}
            className={styles['attachment-btn']}
            color="transparent"
            onClick={() => {
              !token ? setSignupModal(true) : setModalShow(true);
            }}
          >
            <img src={item.icon} alt={item.text} />
            {item.text}
          </Button>
        ))}
      </div>
      <CreatePost
        show={modalShow}
        onHide={() => setModalShow(false)}
        setRefresh={setRefresh}
      />
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
};

export default DashboardSearch;
