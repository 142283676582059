import { Fragment, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Label, Button } from 'reactstrap';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getOtpForValidation } from 'src/services/user-service/user-servicec';
import Timer from 'src/components/utils/Timer';
import { ValidationMessage } from 'src/components/validation-message';
import SecureAadhaarModal from './SecureAadhaarModal';
import AppPopUp from 'src/components/bottombar/AppPopUp';

const StepOneForm = ({
  handleSubmit,
  handleChange,
  formValue,
  onSubmit,
  error,
}) => {
  const [showOtp, setShowOtp] = useState(false);
  const initialTime = 30;
  const [regenerateOtp, setRegenerateOtp] = useState(false);
  const [disbaleOtpButton, setDisbaleOtpButton] = useState(false);
  const [changeLabelOTPBtn, setChangeLabelOTPBtn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [verified, setVerified] = useState(false);

  function onChange(value) {
    setVerified(true);
  }

  const handleTimeout = () => {
    setChangeLabelOTPBtn(false);
    setRegenerateOtp(false);
    setDisbaleOtpButton(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const generateOtp = async (event) => {
    const { phone, aadhar } = formValue;

    setDisbaleOtpButton(true);
    if (phone && aadhar) {
      try {
        const otpResponse = await getOtpForValidation(aadhar);
        setRegenerateOtp(true);
        if (otpResponse?.requestId) {
          localStorage.setItem('otp-id', otpResponse?.requestId);
        }
      } catch (error) {
        console.log('error while receving the otp', error);
        return;
      }
    }
  };

  useEffect(() => {
    const { phone, aadhar, email, password } = formValue;

    if (phone.length >= 10 && aadhar.length === 12 && email && password)
      //change phone number if user has differnt number than 10 digits
      setShowOtp(true);
  }, [formValue]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [securedModal, setSecuredModal] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setSecuredModal(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Fragment>
        <div className="wc-form-container">
          <>
            <Form onSubmit={handleSubmit}>
              <div className="wc-form">
                <Form.Group controlId="aadhar">
                  <Form.Label className="mt-4">
                    <b>Aadhar no</b>{' '}
                    <span style={{ color: 'red' }}>
                      {!formValue.aadhar ? '*' : ''}
                    </span>
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      name="aadhar"
                      value={formValue.aadhar}
                      maxLength={12}
                      onChange={handleChange}
                      placeholder="Enter your aadhar no."
                      disabled={showOtp}
                      isInvalid={!!error.aadhar}
                      style={{ position: 'relative' }}
                    />{' '}
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip`}>
                          Disclaimer: All the Information received from Aadhar
                          Verification is totally encrypted and based on mutual
                          understanding of UIDAI and White Collar Professional.
                          To know more, check our privacy policy.
                        </Tooltip>
                      }
                    >
                      <div className="tooltip-img">
                        <img src="/images/tooltip-img.svg" alt="help icon" />
                      </div>
                    </OverlayTrigger>
                  </div>
                  {/* {showOtp && (
                    <Button color="transparent" className="edit-btn">
                      Edit
                    </Button>
                  )} */}
                  <Form.Control.Feedback type="invalid">
                    <ValidationMessage>
                      This field is required.
                    </ValidationMessage>
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="phone">
                  <Form.Label className="mt-4">
                    <b>Phone no.</b> (as per Aadhar Card){' '}
                    <span style={{ color: 'red' }}>
                      {!formValue.phone ? '*' : ''}
                    </span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="phone"
                    value={formValue.phone}
                    onChange={handleChange}
                    placeholder="Enter your phone no."
                    className="form-input"
                    disabled={showOtp}
                    isInvalid={!!error.phone}
                  />
                  {/* {showOtp && (
                    <Button color="transparent" className="edit-btn">
                      Edit
                    </Button>
                  )} */}

                  <Form.Control.Feedback type="invalid">
                    <ValidationMessage>
                      This field is required.
                    </ValidationMessage>
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label className="mt-4">
                    <b>Email ID</b>{' '}
                    <span style={{ color: 'red' }}>
                      {!formValue.email ? '*' : ''}
                    </span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="email"
                    value={formValue.email}
                    onChange={handleChange}
                    placeholder="Enter your email ID"
                    className="form-input"
                    disabled={showOtp}
                    isInvalid={!!error.email}
                  />
                  {/* {showOtp && (
                    <Button color="transparent" className="edit-btn">
                      Edit
                    </Button>
                  )} */}

                  <Form.Control.Feedback type="invalid">
                    <ValidationMessage>
                      This field is required.
                    </ValidationMessage>
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="password"
                  className="form-input-container"
                >
                  <Form.Label className="mt-4">
                    <b>Password</b>
                    <span style={{ color: 'red' }}>
                      {!formValue.password ? '*' : ''}
                    </span>
                  </Form.Label>
                  <div className="input-container">
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formValue.password}
                      onChange={handleChange}
                      placeholder="Enter password"
                      isInvalid={!!error.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      <ValidationMessage>
                        This field is required.
                      </ValidationMessage>
                    </Form.Control.Feedback>
                    <Button
                      color="transparent"
                      onClick={togglePasswordVisibility}
                      className={error.password ? 'edit-btn-error' : 'edit-btn'}
                    >
                      {showPassword ? (
                        <img alt="icon" src="/images/eyeOpenIcon.png" />
                      ) : (
                        <img alt="icon" src="/images/eyeCloseIcon.png" />
                      )}
                    </Button>
                  </div>
                </Form.Group>

                {showOtp && (
                  <div>
                    <div
                      style={{
                        marginTop: '40px',

                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '2rem',
                        width: '100%',
                      }}
                    >
                      <div>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-input-container otp-container">
                        <Label className="form-label" htmlFor="otp">
                          <b>{isMobile ? '' : 'OTP'}</b>
                        </Label>
                        <input
                          id="otp"
                          type="text"
                          name="otp"
                          value={formValue.otp}
                          onChange={handleChange}
                          placeholder="Enter OTP"
                          className="form-input"
                          style={{ width: '10rem' }}
                        />
                        <Button
                          className="otp-generate-btn"
                          disabled={disbaleOtpButton || !verified}
                          onClick={(e) => generateOtp()}
                          style={{
                            width: isMobile ? '100px' : '150px',
                            fontSize: isMobile ? '10px' : '14px',
                            padding: isMobile ? '10px 12px' : ' 14px 16px;',
                          }}
                        >
                          {changeLabelOTPBtn
                            ? 'Generate OTP'
                            : ' Regenerate OTP'}
                        </Button>
                        {error.otp && (
                          <p
                            className="error"
                            style={{ width: '120px', marginLeft: '6px' }}
                          >
                            {error.otp}
                          </p>
                        )}
                        {regenerateOtp && (
                          <div>
                            <Timer
                              initialTime={initialTime}
                              onTimeout={handleTimeout}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {' '}
              <Button className="form-submit" type="submit" onClick={onSubmit}>
                {<span className="font-bold-text">Verify & Proceed</span>}
              </Button>
            </div>
          </>
          {isMobile && <AppPopUp bottom="0" />}
        </div>
      </Fragment>
      <SecureAadhaarModal
        securedModal={securedModal}
        setSecuredModal={setSecuredModal}
      />
    </>
  );
};

export default StepOneForm;
