import axiosInstance from 'src/config/api';
import {
  IAttachment,
  IComment,
  IJobResponse,
} from '../post-service/post-service.interface';

export const fetchJobs = async (
  pageNumber: number,
  pageSize?: number,
  jobId?: string,
  filters?: {
    qualification?: string;
    location?: string;
    jobName?: string;
    jobMode?: string;
    jobExperience?: string;
    jobType?: string;
    jobTenure?: string;
  }
): Promise<IJobResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const params: any = {
      pageNumber,
      pageSize,
      sortBy: 'createdOn',
    };

    if (filters?.qualification) {
      params.qualification = filters.qualification;
    }
    if (filters?.location) {
      params.location = filters.location;
    }
    if (filters?.jobName) {
      params.jobName = filters.jobName;
    }
    if (filters?.jobMode) {
      params.jobMode = filters.jobMode;
    }
    if (filters?.jobExperience) {
      params.jobExperience = filters.jobExperience;
    }
    if (filters?.jobTenure) {
      params.jobTenure = filters.jobTenure;
    }
    if (filters?.jobType) {
      params.jobType = filters.jobType;
    }
    if (jobId) {
      params._id = jobId;
    }

    const response = await axiosInstance.get(`${baseUrl}/api/v1/job`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error while fetching the jobs', error);
    throw error;
  }
};

export const createJobs = async (
  attachments: IAttachment[],
  title: any, //Companyname
  content: any,
  link: any,
  endedOn: any,
  jobQualification: any,
  jobLocation: any,
  jobOpening: any,
  jobExperience: any,
  jobAmount: any,
  jobSkills: any,
  jobType: any,
  jobTenure: any,
  jobContactName: any,
  jobContactNumber: any,
  jobContactEmail: any,
  jobId: any
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/job`, {
      attachments,
      title, //Companyname
      content,
      link,
      endedOn,
      jobQualification,
      jobLocation,
      jobOpening,
      jobExperience,
      jobAmount,
      jobSkills,
      jobType,
      jobTenure,
      jobContactName,
      jobContactNumber,
      jobContactEmail,
      jobId,
    });
    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
  return;
};

export const deleteJob = async (postId: string): Promise<IComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.delete(`${baseUrl}/api/v1/job/${postId}`)).data;
};

export const getAllQualificationLocation = async (qualification: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  let payload = {
    currentQualification: qualification,
  };

  const response = await axiosInstance.post(
    `${baseUrl}/api/v1/job/allqualifications/qualification`,
    payload
  );

  return response.data;
};

export const fetchJobsSearch = async (
  jobName?: string
): Promise<IJobResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const params: any = {
      pageNumber: 1,
      pageSize: 20,
      sortBy: 'createdOn',
    };

    if (jobName) {
      params.title = jobName;
    }

    const response = await axiosInstance.get(`${baseUrl}/api/v1/job/name`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error while fetching the jobs', error);
    throw error;
  }
};
