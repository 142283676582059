import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import styles from './membership-header.module.css';

import {
  faBell,
  faDiamond,
  faPenToSquare,
  faPhone,
  faSpinner,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useStorage } from 'src/context/storage.context';
import { UserContext } from 'src/context/user.context';
import { uploadAttachment } from 'src/services/attachment-service/attachment-service.client';
import { IAttachment } from 'src/services/post-service/post-service.interface';
import { IUser } from 'src/services/user-service/user-service.interface';
import {
  deleteFollowUser,
  fetchUserDetails,
  followUser,
  handleProfileUpdate,
  updateUserCity,
  updateUserContactNumber,
  updateUserState,
} from 'src/services/user-service/user-servicec';
import { resolveBEAsset } from 'src/utils/urls';
import { AvatarUpload } from '../avatar-upload';
import { MessageContext } from 'src/context/message.context';
import { Report } from '../report';
import UpgradeToProfessional from '../upgrade-to-professional/upgradeToProfessional';
import ChangePasswordForm from '../navbar/change-password';
import DeleteAccount from '../navbar/delete-account';
import { faLock, faPowerOff, faTrash } from '@fortawesome/free-solid-svg-icons';
import LocationModal from '../userUpdateModal/LocationModal';
import ContactNumberModal from '../userUpdateModal/contactNumUpdate';
import districtData from 'src/geographicalDetails/district';
import SignupModal from '../stepper/SignupModal';
import { categoryMap } from '../navbar/search.helper';

const MembershipHeader = () => {
  const { fetchMessageFromId } = useContext(MessageContext);
  const { following, id, addFollowing, removeFollowing } =
    useContext(UserContext);
  const [showReportUser, setShowReportUser] = useState(false);

  const [user, setUser] = useState<IUser>();
  const [loadingProfilePic, setLoadingProfilePic] = useState(false);
  const [loadingHideContactNumber, setLoadingHideContactNumber] =
    useState(false);
  const [loadingHideEmailAddress, setLoadingHideEmailAddress] = useState(false);
  const [loadingFollowing, setLoadingFollowing] = useState(false);
  const router = useNavigate();
  const { profilePicUpdated, setProfilePicUpdated } = useStorage();

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const profileId = query.get('profileId') || localStorage.getItem('id');

  const userFollowings = following?.filter(({ type }: any) => type === 'USER');
  const followingId = userFollowings.find(
    (follow: any) => follow.userId === profileId
  )?._id;

  const getUserDetails = async (userId: string) => {
    const res = await fetchUserDetails(userId);
    setUser(res);
  };

  const handleFollow = async (): Promise<void> => {
    if (profileId) {
      setLoadingFollowing(true);
      const { data: newFollowing } = await followUser(id, profileId);
      addFollowing(newFollowing);
      setLoadingFollowing(false);
    }
  };

  const handleUnFollow = async (followingId: string): Promise<void> => {
    setLoadingFollowing(true);
    const { data: newFollowing } = await deleteFollowUser(id, followingId);
    removeFollowing(newFollowing);
    setLoadingFollowing(false);
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId as string);
    }
  }, [profileId]);

  const updateUser = (updateUser: Partial<IUser>) => {
    const updatedUser = { ...user, ...updateUser, _id: id };
    //@ts-ignore
    setUser(updatedUser);

    let localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
    localStorageUser = { ...localStorageUser, ...updateUser };
    localStorage.setItem('user', JSON.stringify(localStorageUser));
  };

  const handleProfilePicUpdate = async (image: File | null) => {
    setLoadingProfilePic(true);
    if (image) {
      const attachmentsArr = await uploadAttachment([image]);
      const picture: IAttachment = attachmentsArr?.[0];
      await handleProfileUpdate(id, 'picture', attachmentsArr[0]);
      if (picture) {
        updateUser({ picture });
        setProfilePicUpdated(profilePicUpdated + 1);
      }
    }
    setLoadingProfilePic(false);
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleView = (key: string) => {
    const urlParams = new URLSearchParams(searchParams);
    urlParams.set('view', key);
    urlParams.delete('postId');

    navigate({
      search: createSearchParams(urlParams).toString(),
    });
  };

  const goToChatWindow = async (data: any) => {
    router(`/chatwindow?userid=${profileId}`);
    await fetchMessageFromId(id, data);
  };

  const onLogout = () => {
    localStorage.clear();
    router(`/`);
  };
  const fullName = user?.name;
  const firstName = fullName ? fullName.split(' ')[0] : undefined;
  // const lastName = fullName ? fullName.split(' ')[1] : undefined;
  const view = searchParams.get('view') || '';
  const [showDropdown, setShowDropdown] = useState(false);
  const token = localStorage.getItem('token') || '';
  const [contactNumberModal, setContactNumberModal] = useState(false);
  const [updateContactNumber, setUpdateContactNumber] = useState('');

  const handleLocationUpdate = async (state: string, city: string) => {
    if (state.trim() && city.trim()) {
      try {
        await Promise.all([
          updateUserCity(profileId, city.trim(), token),
          updateUserState(profileId, state.trim(), token),
          getUserDetails(profileId as string),
        ]);
      } catch (error) {
        console.error('Failed to update location:', error);
      }
    }
  };

  const handleContactNumberUpdate = async () => {
    if (updateContactNumber.trim()) {
      await updateUserContactNumber(
        profileId,
        updateContactNumber.trim(),
        token
      );
    }

    setContactNumberModal(false);
    setUpdateContactNumber('');

    window.location.reload();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedState, setSelectedState] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCity, setSelectedCity] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cities, setCities] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);
  useEffect(() => {
    if (selectedState) {
      setCities(districtData[selectedState]);
      setSelectedCity(''); // Reset city selection when state changes
    }
  }, [selectedState]);

  const currentUrl = window.location.href;
  const isProfile = currentUrl.includes('profile');

  const handleEditProfile = () => {
    if (profileId) {
      router(`/profile?profileId=${profileId}`);
    }
  };
  const [signupModal, setSignupModal] = useState(false);
  return (
    <div className={styles['profile-sidebar']}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {isProfile && (
          <div onClick={() => router(-1)}>
            <div className={styles['membership-navbar']}>
              <img src="/images/back-arrow.svg" alt="back-btn" />
              back
            </div>
          </div>
        )}
        <div className={styles['profile-avatar']}>
          <img
            src={
              user?.picture?.path
                ? resolveBEAsset(`${user.picture?.path}`)
                : '/images/defaultProfilePic.svg'
            }
            alt="user"
            className={classNames(
              styles['avatar-img'],
              !isProfile ? styles['dashboard-avatar-img'] : ''
            )}
          />
          {profileId === id && isProfile && (
            <AvatarUpload onProcessed={(file) => handleProfilePicUpdate(file)}>
              <Button color="transparent" className={styles['edit-btn']}>
                {loadingProfilePic && (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                    &nbsp;
                  </>
                )}
                Edit <img alt="icon" src="/images/editWhiteIcon.png" />
              </Button>
            </AvatarUpload>
          )}
        </div>
        {isProfile && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '-5px',
              minWidth: '42px',
            }}
          >
            <Dropdown
              isOpen={showDropdown}
              toggle={() => setShowDropdown(!showDropdown)}
              className={styles['sort-dropdown-mobile']}
            >
              <DropdownToggle
                style={{ background: 'none', border: 'none', padding: 0 }}
              >
                {profileId !== id && (
                  <div onClick={() => setShowDropdown(!showDropdown)}>
                    <img
                      src="/images/sort-by.svg"
                      alt="sortby"
                      height={18}
                      width={18}
                    />
                  </div>
                )}
              </DropdownToggle>
              <DropdownMenu className={styles['sort-dropdown-menu-mobile']}>
                <DropdownItem>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        color: 'var(--grey-grey-90, #1A1A1A)',
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        paddingLeft: '14px',
                        textOverflow: 'ellipsis',
                        marginRight: '20px',
                      }}
                    >
                      {profileId === id ? firstName : `${firstName}'s`}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <img
                        src="/images/close-btn.svg"
                        alt="close-btn"
                        height={20}
                        width={20}
                      />
                    </div>
                  </div>
                </DropdownItem>
                {categoryMap(id === profileId, user?.isProfessional).map(
                  (item, index) => (
                    <DropdownItem key={index}>
                      <Button
                        onClick={() => handleView(item.key)}
                        color="transparent"
                        className="option-item"
                      >
                        <img alt="icon" src={item.icon} />
                        &nbsp;&nbsp;{item.title}&nbsp;
                        {item.key === 'questions'
                          ? `(${user?.postCount ? user?.postCount : 0})`
                          : item.key === 'published-works'
                          ? `(${user?.workCount ? user?.workCount : 0})`
                          : item.key === 'answers'
                          ? `(${
                              (user?.commentCount ?? 0) +
                              (user?.replyCount ?? 0)
                            })`
                          : item.key === 'networks'
                          ? `(${
                              (user?.followerCount ?? 0) +
                              (user?.followingCount ?? 0)
                            })`
                          : ''}
                        {view === item.key && (
                          <span className="floatingIcon">
                            <FontAwesomeIcon size="sm" icon={faDiamond} />
                          </span>
                        )}
                      </Button>
                    </DropdownItem>
                  )
                )}
                {id === profileId && (
                  <DropdownItem>
                    <Button
                      onClick={() => router('/update')}
                      color="transparent"
                      className="option-item"
                    >
                      <span className="floatingIcon">
                        <FontAwesomeIcon size="sm" icon={faBell} />
                      </span>
                      &nbsp; &nbsp;Notifications
                    </Button>
                  </DropdownItem>
                )}
                {id !== profileId && (
                  <DropdownItem onClick={() => setShowReportUser(true)}>
                    {' '}
                    <Button color="transparent" className="option-item">
                      <img src="/images/reportIco.svg" alt="report" />
                      <span>&nbsp; Report User</span>
                    </Button>
                  </DropdownItem>
                )}
                {id === profileId && (
                  <DropdownItem>
                    <div
                      style={{
                        color: 'var(--grey-grey-90, #1A1A1A)',
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        paddingLeft: '14px',
                        textOverflow: 'ellipsis',
                        marginRight: '20px',
                      }}
                    >
                      Settings
                    </div>
                  </DropdownItem>
                )}
                {id === profileId && !user?.isProfessional && (
                  <DropdownItem onClick={() => setShowUpgradeModal(true)}>
                    <Button color="transparent" className="option-item">
                      <FontAwesomeIcon icon={faUserTie} />
                      <span>&nbsp; Professional Registration</span>
                    </Button>
                  </DropdownItem>
                )}
                {id === profileId && (
                  <DropdownItem>
                    <Button color="transparent" className="option-item">
                      <a
                        href="technicalsupport/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <img src="/images/help-support.svg" alt="Icon" />
                        &nbsp; Help & Support
                      </a>
                    </Button>
                  </DropdownItem>
                )}
                {id === profileId && (
                  <DropdownItem>
                    <Button color="transparent" className="option-item">
                      <a
                        href="https://www.whitecollarprofessional.com/legal-policies-wcp_community/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <img src="/images/legal-policy.svg" alt="Icon" />
                        &nbsp; Legal Policy{' '}
                      </a>
                    </Button>
                  </DropdownItem>
                )}
                {id === profileId && (
                  <DropdownItem onClick={() => setShowDeleteAccount(true)}>
                    <Button color="transparent" className="option-item">
                      <FontAwesomeIcon size="sm" icon={faTrash} />
                      &nbsp;&nbsp;Delete Account
                    </Button>
                  </DropdownItem>
                )}
                {id === profileId && (
                  <DropdownItem onClick={() => setShowChangePassword(true)}>
                    <Button color="transparent" className="option-item">
                      <FontAwesomeIcon size="sm" icon={faLock} />
                      &nbsp;&nbsp;Change Password
                    </Button>{' '}
                  </DropdownItem>
                )}
                {id === profileId && (
                  <DropdownItem onClick={() => onLogout()}>
                    <Button color="transparent" className="option-item">
                      <FontAwesomeIcon size="sm" icon={faPowerOff} />
                      &nbsp;&nbsp;Logout
                    </Button>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
      {showChangePassword && (
        <ChangePasswordForm
          show={showChangePassword}
          onHide={() => setShowChangePassword(false)}
        />
      )}
      {showDeleteAccount && (
        <DeleteAccount
          show={showDeleteAccount}
          onHide={() => setShowDeleteAccount(false)}
        />
      )}
      <div
        className={classNames(
          styles['user-details'],
          !isProfile ? styles['dashboard-user-details'] : ''
        )}
      >
        <h3>{user?.name}</h3>
        <p>
          {[user?.city, user?.state].filter((value) => value).join(', ')}
          &nbsp;
          {id === profileId && (
            <div style={{ alignItems: 'center' }}>
              {!user?.state && (
                <span
                  style={{
                    borderBottom: '0.5px dotted #000',
                    cursor: 'pointer',
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  + Add Location
                </span>
              )}
              &nbsp;
              {isProfile && (
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={() => setModalOpen(true)}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          )}
        </p>

        {profileId !== id && user?.isProfessional && (
          <div className={styles.actionButtonContainer}>
            <Button
              onClick={() => goToChatWindow(user)}
              className={styles.actionButton}
              color="primary"
            >
              Message
            </Button>
            &nbsp;
            <Button
              onClick={
                followingId ? () => handleUnFollow(followingId) : handleFollow
              }
              outline
              className={classNames(
                styles.actionButton,
                styles.actionButtonPrimary
              )}
            >
              {followingId ? 'Unfollow' : 'Follow'}
              {loadingFollowing && (
                <>
                  &nbsp;
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
                </>
              )}
            </Button>
          </div>
        )}
      </div>
      {(user?.emailAddress || user?.contactNumber) && (
        <div
          className={classNames(
            styles['user-contact'],
            !isProfile ? styles['dashboard-user-contact'] : ''
          )}
        >
          {user?.contactNumber && (
            <div
              className={classNames(
                styles['contact-info'],
                !isProfile ? styles['dashboard-contact-info'] : ''
              )}
            >
              <span>
                <img
                  alt="icon"
                  src="/images/phoneIcon.png"
                  style={{ marginRight: '8px' }}
                />
                {user?.contactNumber}
              </span>{' '}
              {profileId === id ? (
                <div>
                  {' '}
                  {isProfile && (
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setContactNumberModal(true)}
                    />
                  )}{' '}
                  <Button
                    className={classNames(
                      styles['info-btn'],
                      !isProfile ? styles['dashboard-info-btn'] : ''
                    )}
                    color="transparent"
                    onClick={async () => {
                      setLoadingHideContactNumber(true);
                      const updatedUser = await handleProfileUpdate(
                        id,
                        'isHideContactNumber',
                        !user?.isHideContactNumber
                      );
                      updateUser(updatedUser);
                      setLoadingHideContactNumber(false);
                    }}
                  >
                    {loadingHideContactNumber ? (
                      <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                    ) : user?.isHideContactNumber ? (
                      <img alt="icon" src="/images/eyeCloseIcon.png" />
                    ) : (
                      <img alt="icon" src="/images/eyeOpenIcon.png" />
                    )}
                  </Button>
                </div>
              ) : (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          )}
          {!user?.contactNumber && profileId === id && (
            <div
              className={classNames(
                styles['contact-info'],
                !isProfile ? styles['dashboard-contact-info'] : ''
              )}
            >
              <span>
                <img
                  alt="icon"
                  src="/images/phoneIcon.png"
                  style={{ marginRight: '8px' }}
                />{' '}
                <span
                  style={{
                    borderBottom: '0.5px dotted #000',
                    cursor: 'pointer',
                  }}
                  onClick={() => setContactNumberModal(true)}
                >
                  + Add Phone Number
                </span>
              </span>
              <div>
                {isProfile && (
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    onClick={() => setContactNumberModal(true)}
                    style={{ cursor: 'pointer' }}
                  />
                )}{' '}
                <Button
                  className={classNames(
                    styles['info-btn'],
                    !isProfile ? styles['dashboard-info-btn'] : ''
                  )}
                  color="transparent"
                  onClick={async () => {
                    setLoadingHideContactNumber(true);
                    const updatedUser = await handleProfileUpdate(
                      id,
                      'isHideContactNumber',
                      !user?.isHideContactNumber
                    );
                    updateUser(updatedUser);
                    setLoadingHideContactNumber(false);
                  }}
                >
                  {loadingHideContactNumber ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                  ) : user?.isHideContactNumber ? (
                    <img alt="icon" src="/images/eyeCloseIcon.png" />
                  ) : (
                    <img alt="icon" src="/images/eyeOpenIcon.png" />
                  )}
                </Button>
              </div>
            </div>
          )}
          {user?.emailAddress && (
            <div
              className={classNames(
                styles['contact-info'],
                !isProfile ? styles['dashboard-contact-info'] : ''
              )}
            >
              <span
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <img alt="icon" src="/images/mailIcon.png" />{' '}
                {user?.emailAddress}
              </span>{' '}
              {profileId === id ? (
                <Button
                  className={classNames(
                    styles['info-btn'],
                    !isProfile ? styles['dashboard-info-btn'] : ''
                  )}
                  color="transparent"
                  onClick={async () => {
                    setLoadingHideEmailAddress(true);
                    const updatedUser = await handleProfileUpdate(
                      id,
                      'isHideEmailAddress',
                      !user?.isHideEmailAddress
                    );
                    updateUser(updatedUser);
                    setLoadingHideEmailAddress(false);
                  }}
                >
                  {loadingHideEmailAddress ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                  ) : user?.isHideEmailAddress ? (
                    <img alt="icon" src="/images/eyeCloseIcon.png" />
                  ) : (
                    <img alt="icon" src="/images/eyeOpenIcon.png" />
                  )}
                </Button>
              ) : (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          )}
        </div>
      )}
      {!isProfile && (
        <div className={styles['dashboard-edit-profile']}>
          <Button
            variant="secondary"
            onClick={() => {
              !token ? setSignupModal(true) : handleEditProfile();
            }}
          >
            View / Edit Profile&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ cursor: 'pointer' }}
            />
          </Button>
        </div>
      )}
      {showReportUser && profileId && (
        <Report
          userId={profileId}
          toggle={() => setShowReportUser((prev) => !prev)}
          isOpen={showReportUser}
          onReport={() => setShowReportUser((prev) => !prev)}
        />
      )}
      <UpgradeToProfessional
        show={showUpgradeModal}
        setShow={setShowUpgradeModal}
      />{' '}
      <LocationModal
        isOpen={modalOpen}
        toggleModal={toggleModal}
        handleLocationUpdate={handleLocationUpdate}
      />
      <ContactNumberModal
        isOpen={contactNumberModal}
        toggle={() => setContactNumberModal(!contactNumberModal)}
        contactNumber={updateContactNumber}
        setContactNumber={setUpdateContactNumber}
        onUpdate={handleContactNumberUpdate}
        userContactNumber={user?.contactNumber}
      />
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
};

export default MembershipHeader;
