import { useMemo } from 'react';
import SearchList from 'src/components/search/searchList';
import Layout from 'src/layouts/layout';

import { useLocation } from 'react-router-dom';
import { SEARCH_TYPE } from 'src/config/enums';
import styles from './search.module.css';
import DashboardList from 'src/components/dashboard/dashbaordList';

const Search = () => {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let variables = useQuery();
  const searchType = variables.get('searchType');

  return (
    <Layout contentBordered>
      <div className={styles['wc-dashboard']}>
        <>
          {searchType === SEARCH_TYPE.PROFESSIONAL ? (
            <SearchList />
          ) : (
            <>
              <DashboardList />
            </>
          )}
        </>
      </div>
    </Layout>
  );
};

export default Search;
