import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import ChatList from 'src/components/message/ChatList';
import ChatWindow from 'src/components/message/ChatWindow';
import { MessageContext } from 'src/context/message.context';
import { fetchRecentChatList } from 'src/services/message-service/message-service';
import { resolveBEAsset } from 'src/utils/urls';

const message: React.FC = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openChatList, setOpenChatList] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectedFriend } = useContext(MessageContext);

  const { picture } = JSON.parse(localStorage.getItem('user') || '{}');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [fetchedRecentList, setFechedRecentList] = useState<any>([]);
  const token = localStorage.getItem('token');
  const currentUrl = window.location.href;

  const handleToggleHeader = () => {
    setOpenChatList(!openChatList);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    fetchRecentChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRecentChat = async () => {
    const user: any = localStorage.getItem('user') || '';

    if (!token || !user) {
      return;
    }
    if (user) {
      const userData = JSON.parse(user);

      try {
        const recentChatList = await fetchRecentChatList(userData?._id);
        setFechedRecentList(recentChatList?.data);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const handleRefreshClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await fetchRecentChat();
  };

  if (currentUrl.includes('/message')) {
    return null;
  }
  if (!token) {
    return null;
  }

  return (
    <>
      <div className="messasge-ui">
        {openChatList ? (
          <div className="chat-list-container">
            <div className="chat-list-header" onClick={handleToggleHeader}>
              <img
                src={
                  picture?.path
                    ? resolveBEAsset(picture?.path || '')
                    : '/images/defaultProfilePic.svg'
                }
                alt="user profile"
              />
              <h2>Messages</h2>
              <button
                className="refresh-button"
                onClick={(e) => handleRefreshClick(e)}
              >
                <img
                  src="/images/refresh-cw.svg"
                  alt="refreshIcon"
                  style={{ height: '18px', width: '18px' }}
                />
              </button>

              <span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ color: '#1a1a1a' }}
                />
              </span>
            </div>
            {openChatList ? <ChatList refreshList={fetchedRecentList} /> : null}
          </div>
        ) : (
          <div className="message-ui-container">
            <div className="chat-list-header-main" onClick={handleToggleHeader}>
              <span>
                <img
                  src={
                    picture?.path
                      ? resolveBEAsset(picture?.path || '')
                      : '/images/defaultProfilePic.svg'
                  }
                  alt="user profile"
                />
              </span>
              <h2>Messages</h2>
              <span>
                <FontAwesomeIcon
                  icon={faChevronUp}
                  style={{ color: '#1a1a1a' }}
                />
              </span>
            </div>
          </div>
        )}
        <div
          className={
            openChatList
              ? 'message-list-container'
              : 'message-list-container-close '
          }
        >
          {selectedFriend.map((selData: any) => (
            <ChatWindow
              key={selData?.user?._id}
              messageListData={selData}
              handleRefreshClick={handleRefreshClick}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default message;
