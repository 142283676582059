import styles from './logo.module.css';

type Props = {
  color: string;
  src: string;
};

export default function Logo({ color, src = '/logo.svg' }: Props) {
  return (
    <div className={styles.wrapper} style={{ color }}>
      <img src={src} alt="Logo" className={styles.logo} />
    </div>
  );
}
