import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  IMembership,
  IUser,
} from 'src/services/user-service/user-service.interface';
import {
  fetchMembership,
  getOtpForValidation,
  updateAadhaarOtherUser,
  updateUserProfession,
  uploadUserAttachment,
} from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import NewMembership from '../profile/new-membership';
import { validateMembershipData } from '../profile/membership.helper';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function UpgradeToProfessional({ show, setShow }: Props) {
  // const userObj = JSON.parse(localStorage.getItem('user') || `{}`);
  // const [user, setUser] = useState<IUser>(userObj);
  // const [aadhaarNumber, setAadhaarNumber] = useState<string>('');
  // const [otp, setOtp] = useState<string>('');
  // const [otpSent, setOtpSent] = useState(false);
  // const [otpLoading, setOtpLoading] = useState(false);
  // const [getOtpButtonDisabled, setGetOtpButtonDisabled] = useState(true);
  // const [requestId, setRequestId] = useState<string>('');
  // const [step1Update, setStep1Update] = useState<boolean>(false);
  // const [loadingMembership, setLoadingMembership] = useState(false);
  // const [successMembership, setSuccessMembership] = useState(false);
  // const [membershipFormError, setMembershipFormError] = useState<any>({});
  // const [membershipFormData, setMembershipFormData] = useState<any>({});
  // const [uploadDocsObject, setUploadDocsObject] = useState(null);
  // const [tnc, setTnc] = useState<boolean>(true);

  // const handleAadhaarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputAadhaar = e.target.value;
  //   setAadhaarNumber(inputAadhaar);
  //   setOtpLoading(false);
  //   setOtp('');

  //   // Validate Aadhaar input and enable/disable the "Get OTP" button
  //   const aadhaarPattern = /^[0-9]{12}$/;
  //   const isAadhaarValid = aadhaarPattern.test(inputAadhaar);
  //   setOtpSent(false); // Reset OTP sent status if Aadhaar is modified
  //   setGetOtpButtonDisabled(!isAadhaarValid);
  // };

  // const handleFormChange = (e: any) => {
  //   if (e.target.type === 'file') {
  //     setUploadDocsObject(e.target.files);
  //   }
  //   setMembershipFormData((prevState: any) => ({
  //     ...prevState,
  //     [e.target.name]:
  //       e.target.type === 'checkbox' ? e.target.checked : e.target.value,
  //   }));
  // };

  // const onUpdateMembership = async () => {
  //   const newErrors = validateMembershipData(membershipFormData);
  //   if (Object.keys(newErrors).length === 0) {
  //     try {
  //       if (
  //         membershipFormData.icai ||
  //         membershipFormData.icmai ||
  //         membershipFormData.icsi ||
  //         membershipFormData.bci
  //       ) {
  //         setLoadingMembership(true);

  //         let updateUserPayload = {
  //           name: '',
  //           number: '',
  //           attachments: [],
  //         };
  //         if (membershipFormData.icai && !membershipFormData.icai_disabled) {
  //           updateUserPayload.name = 'ICAI';
  //           updateUserPayload.number = membershipFormData.icai_membership_no;
  //           await updateUserProfession(user._id, user?.token, {
  //             name: updateUserPayload.name,
  //             number: updateUserPayload.number,
  //           });
  //         }
  //         if (membershipFormData.icmai && !membershipFormData.icmai_disabled) {
  //           updateUserPayload.name = 'ICMAI';
  //           updateUserPayload.number = membershipFormData.icmai_membership_no;
  //           await updateUserProfession(user._id, user?.token, {
  //             name: updateUserPayload.name,
  //             number: updateUserPayload.number,
  //             attachments: [],
  //           });
  //         }
  //         if (membershipFormData.icsi && !membershipFormData.icsi_disabled) {
  //           updateUserPayload.name = 'ICSI';
  //           updateUserPayload.number = membershipFormData.icsi_membership_no;
  //           await updateUserProfession(user._id, user.token, {
  //             name: updateUserPayload.name,
  //             number: updateUserPayload.number,
  //           });
  //         }
  //         if (membershipFormData.bci && !membershipFormData.bci_disabled) {
  //           let fileResponse;
  //           if (uploadDocsObject) {
  //             const formData = new FormData();
  //             formData.append('file', uploadDocsObject[0]);
  //             fileResponse = await uploadUserAttachment(formData, user.token);
  //           }
  //           updateUserPayload.name = 'BCI';
  //           updateUserPayload.number = membershipFormData.bci_membership_no;
  //           await updateUserProfession(user._id, user.token, {
  //             name: updateUserPayload.name,
  //             number: updateUserPayload.number,
  //             attachments: fileResponse ? [fileResponse] : [],
  //           });
  //         }
  //         setLoadingMembership(false);
  //         setSuccessMembership(true);
  //         setTimeout(() => {
  //           setSuccessMembership(false);
  //           setShow(false);
  //         }, 2500);
  //       }
  //     } catch (error) {
  //       setLoadingMembership(false);
  //     }
  //   } else {
  //     setMembershipFormError(newErrors);
  //   }
  // };

  // const handleGetOtp = async () => {
  //   setOtpLoading(true);
  //   setOtpSent(true);
  //   const res = await getOtpForValidation(aadhaarNumber);
  //   setRequestId(res.requestId);
  //   setOtpLoading(false);
  // };

  // const handleAddMembership = async () => {
  //   setStep1Update(true);
  //   const res = await updateAadhaarOtherUser(
  //     user._id,
  //     requestId,
  //     aadhaarNumber,
  //     otp
  //   );
  //   setStep1Update(false);
  //   localStorage.setItem('user', JSON.stringify({ ...user, ...res }));
  //   setUser({ ...user, ...res });
  // };

  // const renderStep1 = () => (
  //   <>
  //     {step1Update ? (
  //       <LoadingLottie height={300} />
  //     ) : (
  //       <div>
  //         <Form>
  //           <Form.Group controlId="aadhaar">
  //             <Row className="mb-4 mt-4 justify-content-center">
  //               <Col md={8} sm={6}>
  //                 <Form.Control
  //                   type="text"
  //                   placeholder="Enter Aadhaar Number"
  //                   value={aadhaarNumber}
  //                   onChange={handleAadhaarChange}
  //                   pattern="[0-9]{12}"
  //                   maxLength={12}
  //                   required
  //                 />
  //               </Col>
  //             </Row>
  //           </Form.Group>
  //           <Form.Group controlId="otp">
  //             <Row className="mb-4 justify-content-center">
  //               <Col md={6} sm={6}>
  //                 <Form.Control
  //                   type="text"
  //                   placeholder="Enter OTP"
  //                   value={otp}
  //                   onChange={(e) => setOtp(e.target.value)}
  //                   disabled={!otpSent}
  //                 />
  //               </Col>

  //               <Col md={2} sm={6}>
  //                 <Button
  //                   style={{ width: '100%' }}
  //                   variant="secondary"
  //                   onClick={handleGetOtp}
  //                   disabled={getOtpButtonDisabled || otpSent}
  //                 >
  //                   Get OTP &nbsp;
  //                   {otpLoading && (
  //                     <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
  //                   )}
  //                 </Button>
  //               </Col>
  //             </Row>
  //           </Form.Group>
  //         </Form>
  //       </div>
  //     )}
  //   </>
  // );

  const [membershipLoading, setMembershipLoading] = useState<boolean>(false);
  const [membership, setMembership] = useState<IMembership[]>([]);
  const userString = localStorage.getItem('user') || '';
  const loggedInuser = userString ? JSON.parse(userString) : '';
  const profileId = loggedInuser._id;

  const handleClose = () => setShow(false);

  const getMembership = async () => {
    setMembershipLoading(true);
    const res = await fetchMembership(profileId);
    setMembership(res.data);
    setMembershipLoading(false);
  };

  useEffect(() => {
    if (show) {
      getMembership();
    }
    // else {
    //   setAadhaarNumber('');
    //   setOtp('');
    //   setOtpSent(false);
    //   setOtpLoading(false);
    //   setGetOtpButtonDisabled(true);
    //   setStep1Update(false);
    // }
  }, [show]);

  if (membershipLoading) {
    return (
      <Modal show={true}>
        <LoadingLottie height={410} />
      </Modal>
    );
  } else if (
    membership.length &&
    membership.some((item) => item.name !== 'NONE')
  ) {
    return (
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Professional Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {membership.find((mem) => mem.isVerified)
            ? 'Please Login back again to access Professional Account'
            : 'Currently your Account is under Review, will get active within 24-48 Hrs'}
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <NewMembership showAddMembership={show} setShowAddMembership={setShow} />
    );
  }
  // else if (tnc) {
  //   return (
  //     <Modal show={show} onHide={handleClose} size="lg">
  //       <Modal.Header closeButton>
  //         <Modal.Title>Professional Registration</Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <ol>
  //           <li>
  //             Professional hereby means Chartered Accountant, Company Secretary,
  //             Cost and Work Accountant, or/and Advocate (Lawyer).
  //           </li>

  //           <li>
  //             While clicking on the button “I Understand”, you confirm that all
  //             the information provided by you is correct and accurate to the
  //             best of your knowledge and experience.
  //           </li>

  //           <li>
  //             For more details, please refer to our Legal Policies, Professional
  //             Community Guidelines, and Institutional Ethical Guidelines.
  //           </li>
  //         </ol>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button variant="secondary" onClick={() => setTnc(false)}>
  //           I Understand
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }
  //  else {
  //   return (
  //     <Modal show={show} onHide={handleClose} size="lg">
  //       <Modal.Header closeButton>
  //         <Modal.Title>Professional Registration</Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>{renderStep1()}</Modal.Body>
  //       <Modal.Footer>
  //         <Button
  //           variant="secondary"
  //           onClick={handleAddMembership}
  //           disabled={!otp.length}
  //         >
  //           Add Membership
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }
}
