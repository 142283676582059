import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import styles from './hiringJobs.module.css';
import { experienceOptions } from './CreateJob';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function HiringJobsFilter() {
  const [searchParams] = useSearchParams();
  const urlParams = new URLSearchParams(searchParams);
  const navigate = useNavigate();
  const [workMode, setWorkMode] = useState('');

  const handleWorkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newWorkMode = event.target.value;
    setWorkMode(newWorkMode);
    urlParams.set('jobMode', newWorkMode);
    navigate(`/jobs?${urlParams.toString()}`);
  };

  const [selectedExperiences, setSelectedExperiences] = useState<string[]>([]);

  useEffect(() => {
    const jobExperienceParam = searchParams.get('jobExperience') || '';
    const selectedExperienceValues = jobExperienceParam.split(',');
    setSelectedExperiences(selectedExperienceValues);
  }, [searchParams]);

  const handleCheckboxChange = (value: string) => {
    let updatedSelectedExperiences: string[];
    if (selectedExperiences.includes(value)) {
      updatedSelectedExperiences = selectedExperiences.filter(
        (exp) => exp !== value
      );
    } else {
      updatedSelectedExperiences = [...selectedExperiences, value];
    }
    setSelectedExperiences(updatedSelectedExperiences);
    urlParams.set('jobExperience', updatedSelectedExperiences.join(','));
    navigate(`/jobs?${urlParams.toString()}`);
  };

  const [empType, setEmpType] = useState('');
  const handleEmpTypeChange = (event: any) => {
    const newEmpType = event.target.value;
    setEmpType(newEmpType);
    urlParams.set('jobType', newEmpType);
    navigate(`/jobs?${urlParams.toString()}`);
  };

  const [empTenure, setEmpTenure] = useState('');
  const handleEmpTenureChange = (event: any) => {
    const newEmpTenure = event.target.value;
    setEmpTenure(newEmpTenure);
    urlParams.set('jobTenure', newEmpTenure);
    navigate(`/jobs?${urlParams.toString()}`);
  };

  const jobMode = searchParams.get('jobMode') || '';
  const jobType = searchParams.get('jobType') || '';
  const jobTenure = searchParams.get('jobTenure') || '';
  const jobExperience = searchParams.get('jobExperience') || '';
  return (
    <div>
      <div className={styles['job-filter-header']}>Additional Filters</div>
      <div className={styles['job-filter-body']}>
        {' '}
        <div>
          <div className={styles['jobs-filters-title']}>
            Employment Tenure{' '}
            {jobTenure.length > 0 && (
              <span>
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setEmpTenure('');
                    urlParams.delete('jobTenure');
                    navigate(`/jobs?${urlParams.toString()}`);
                  }}
                />
              </span>
            )}
          </div>
          <div className={styles['jobs-filters-body']}>
            <Form.Check
              type="radio"
              id="permanentRole"
              label="Permanent"
              name="employmentTenure"
              value="Permanent Role"
              checked={empTenure === 'Permanent Role'}
              onChange={handleEmpTenureChange}
              className={styles['radio-btn']}
            />
            <Form.Check
              type="radio"
              id="assignmentBased"
              label="Assignment"
              name="employmentTenure"
              value="Assignment Based"
              checked={empTenure === 'Assignment Based'}
              onChange={handleEmpTenureChange}
              className={styles['radio-btn']}
            />
          </div>
        </div>
        <div>
          <div
            className={styles['jobs-filters-title']}
            style={{ width: '100%' }}
          >
            Work Mode{' '}
            {jobMode.length > 0 && (
              <span>
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setWorkMode('');
                    urlParams.delete('jobMode');
                    navigate(`/jobs?${urlParams.toString()}`);
                  }}
                />
              </span>
            )}
          </div>
          <div className={styles['jobs-filters-body']}>
            {' '}
            <Form.Check
              type="radio"
              id="remote"
              label="Remote"
              name="location"
              value="Remote"
              checked={workMode === 'Remote'}
              onChange={handleWorkModeChange}
              className={styles['radio-btn']}
            />
            <Form.Check
              type="radio"
              id="onSite"
              label="On Site"
              name="location"
              value="onSite"
              checked={workMode === 'onSite'}
              onChange={handleWorkModeChange}
              className={styles['radio-btn']}
            />
          </div>
        </div>
        <div>
          <div className={styles['jobs-filters-title']}>
            Experience{' '}
            {jobExperience.length > 0 && (
              <span>
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    urlParams.delete('jobExperience');
                    navigate(`/jobs?${urlParams.toString()}`);
                  }}
                />
              </span>
            )}
          </div>
          <div className={styles['jobs-filters-body']}>
            {experienceOptions.map((option) => (
              <div
                className={styles['jobs-filters-experience-wrapper']}
                key={option}
              >
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedExperiences.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className={styles['jobs-filters-title']}>
            Employment Type{' '}
            {jobType.length > 0 && (
              <span>
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setEmpType('');
                    urlParams.delete('jobType');
                    navigate(`/jobs?${urlParams.toString()}`);
                  }}
                />
              </span>
            )}
          </div>
          <div className={styles['jobs-filters-body']}>
            <Form.Check
              type="radio"
              id="fullTime"
              label="Full Time"
              name="employmentType"
              value="Full Time"
              checked={empType === 'Full Time'}
              onChange={handleEmpTypeChange}
              className={styles['radio-btn']}
            />
            <Form.Check
              type="radio"
              id="partTime"
              label="Part Time"
              name="employmentType"
              value="Part Time"
              checked={empType === 'Part Time'}
              onChange={handleEmpTypeChange}
              className={styles['radio-btn']}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
