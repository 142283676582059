import { Col, Row } from 'reactstrap';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'src/context/user.context';
import {
  IMembership,
  IUser,
} from 'src/services/user-service/user-service.interface';
import {
  fetchMembership,
  fetchUserDetails,
} from 'src/services/user-service/user-servicec';
import { getMembershipColor } from 'src/utils/text';
import { LoadingLottie } from '../lotties/loading';
import styles from './profile.module.css';
import NewMembership from './new-membership';

import axios from 'axios';
import classNames from 'classnames';

type IProp = {
  user: IUser;
};

const Membership = ({ user }: IProp) => {
  const { id } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [membership, setMembership] = useState<IMembership[]>([]);
  const [showAddMembership, setShowAddMembership] = useState(false);

  const { _id: userId } = JSON.parse(localStorage.getItem('user') || '{}');
  const token = localStorage.getItem('token');

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const profileId = query.get('profileId');
  const [loggedInuser, setLoggedInUser] = useState<IUser | null>(null);

  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setLoggedInUser(userRes);
      if (userRes?.aboutMe && profileId === id) {
        setAboutMe(userRes.aboutMe);
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };
  const getMembershipDetails = async (profileId: string) => {
    setLoading(true);
    const membershipRes = await fetchMembership(profileId as string);

    setMembership(membershipRes.data);
    setLoading(false);
  };
  const [aboutMe, setAboutMe] = useState('');
  useEffect(() => {
    if (profileId) {
      getMembershipDetails(profileId);
      getUserDetails(profileId);
    }
    if (loggedInuser?.aboutMe) {
      setAboutMe(loggedInuser?.aboutMe);
    }
  }, [profileId]);

  const handleUpdateAboutMe = async () => {
    if (!aboutMe.trim()) {
      return;
    }

    try {
      const baseUrl = process.env.REACT_APP_API_HOST;

      await axios.patch(
        `${baseUrl}/api/v1/user/${userId}/aboutMe`,
        {
          aboutMe,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error: unknown) {
      if (error instanceof Error && axios.isAxiosError(error)) {
        console.log(
          error.response?.data?.message || 'Failed to update About Me'
        );
      } else {
        console.log('An unknown error occurred', error);
      }
    }
  };

  return (
    <div className={styles['membership-container']}>
      <Col>
        <h2 className={styles['aboutme-heading']}>
          About Me <span>(Bio)</span>
        </h2>
      </Col>

      <Col sm={12} md={12} className="mb-4">
        <div className={styles['about-me-container']}>
          <textarea
            className={classNames('form-control', styles['user-aboutme-input'])}
            value={aboutMe}
            onChange={(e) => setAboutMe(e.target.value)}
            onBlur={() => handleUpdateAboutMe()}
            rows={3}
            placeholder={
              loggedInuser?.aboutMe
                ? loggedInuser?.aboutMe
                : profileId === id
                ? `Tell us about yourself. Share your story, interests, and what drives you. 
This is your space to let others know who you are and what you're passionate about.`
                : `This user is still building his/her bio. Stay tuned to learn more about them!`
            }
            disabled={profileId !== id}
          />
        </div>
      </Col>
      {loggedInuser?.isProfessional && (
        <div>
          <h2 className={styles['container-heading']}>Membership Details</h2>
          {loading ? (
            <LoadingLottie height="250px" />
          ) : (
            <>
              <Row>
                {membership
                  ?.filter((item) => item.name !== 'NONE')
                  .map((item, idx) => (
                    <Col
                      sm={12}
                      md={6}
                      key={`Membership_${item._id}`}
                      className={styles['membership-item']}
                    >
                      <div
                        className={styles['item-content']}
                        style={{
                          backgroundColor: getMembershipColor(item.name),
                          border: `1px solid ${getMembershipColor(item.name)}`,
                        }}
                      >
                        <p className={styles['institute-name']}>
                          <b>Institute</b> {item.name}
                        </p>
                        <p>
                          <b>Membership no.</b> {item.number}
                          {item.isVerified ? (
                            <span>
                              <img
                                src="/images/verificationPassIcon.png"
                                alt=""
                              />
                            </span>
                          ) : (
                            <span>
                              <img
                                src="/images/verificationPendingIcon.png"
                                alt=""
                              />
                            </span>
                          )}
                        </p>
                      </div>
                    </Col>
                  ))}
                {id === profileId && membership.length <= 4 ? (
                  <Col
                    sm={12}
                    md={6}
                    className={styles['membership-item']}
                    onClick={() => setShowAddMembership(true)}
                  >
                    <div className={styles['new-item-add-btn']}>
                      + New membership
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </>
          )}
          <NewMembership
            showAddMembership={showAddMembership}
            setShowAddMembership={setShowAddMembership}
          />
        </div>
      )}
    </div>
  );
};

export default Membership;
