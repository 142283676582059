import { useEffect, useMemo, useRef, useState } from 'react';
import SearchListItem from './listItem';
import { IUser } from 'src/services/user-service/user-service.interface';
import styles from './searchList.module.css';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  fetchCities,
  fetchUserDetails,
  fetchUsers,
  fetchUsersbyMatch,
  getFollowers,
  getFollowings,
} from 'src/services/user-service/user-servicec';
import { LoadingLottie } from 'src/components/lotties/loading';
import AsyncSelect from 'react-select/async';
import { StylesConfig } from 'react-select';
import Select from 'react-select';
import NoData from 'src/components/no-data';
import BottomBar from 'src/components/bottombar/BottomBar';
import SignupModal from 'src/components/stepper/SignupModal';

import { Button } from 'reactstrap';
import ExpertiseFilterProfessional from './expertiseFilter';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

interface OptionType {
  value: string;
  label: string;
}

const options = [
  { value: 'BCI', label: 'Advocate' },
  { value: 'ICAI', label: 'Chartered Accountant' },
  { value: 'ICSI', label: 'Company Secretary' },
  { value: 'ICMAI', label: 'Cost and Work Accountant' },
];

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: '1.5rem',
    width: `${
      window.innerWidth > 1000 && window.innerWidth <= 1200
        ? '180px'
        : window.innerWidth > 1200
        ? '250px'
        : '150px'
    }`,
    border: '1px solid #1a1a1a',
    fontWeight: 'bold',
    color: 'black',
    height: '12px',
    padding: ' 2px 0.25rem 0 ',

    '&:hover': {
      border: '1px solid #1a1a1a',
      fontWeight: 'bold',
      color: 'black',
    },
  }),
  input: (provided) => ({
    ...provided,

    height: '100%',
    margin: 'auto 0.25rem ',
    fontSize: '14px',
  }),
  placeholder: (provided) => ({
    ...provided,
    height: '100%',
    margin: 'auto 0.25rem ',
    fontSize: '14px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 500,
  }),
};
const mobileStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: '24px',
    padding: '0 8px',
    border: '1px solid #333',
    fontWeight: 'bold',
    color: 'black',
    width: '160px',
    minHeight: '20px',
    fontSize: '12px',

    '&:hover': {
      border: '1px solid #1a1a1a',
      fontWeight: 'bold',
      color: 'black',
    },
  }),
  input: (provided) => ({
    ...provided,

    margin: '2px 8px',

    fontSize: '12px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 ',
    width: 'max-content',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 ',
    div: { padding: '4px 3.5px' },
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: '8px',
    marginRight: 0,
    fontSize: '12px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    svg: { height: '11px', width: '11px' },
    color: 'black',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
};

const SearchList = ({ networkPage = false }: { networkPage?: boolean }) => {
  const [refresh, setRefresh] = useState(1);
  const [newSearch, setNewSearch] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageLimit] = useState(30);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [location, setLocation] = useState<OptionType | null>(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('profileId') || localStorage.getItem('id');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [userListCompleted, setUserListCompleted] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [followersListCompleted, setFollowersListCompleted] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [followingListCompleted, setFollowingListCompleted] =
    useState<boolean>(false);

  const [dummyTrigger, setDummyTrigger] = useState<number>(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let variables = useQuery();
  const query = variables.get('searchQuery');
  const [userList, setUserList] = useState<IUser[]>([]);
  const [followingsList, setFollowingsList] = useState<IUser[]>([]);
  const [followersList, setFollowersList] = useState<IUser[]>([]);
  const metaDataId = searchParams.get('profMetaDataId') || '';
  const [isFollowers, setIsFollowers] = useState(false);
  const fetchUser = async () => {
    setPageLoading(true);
    const payload = {
      pageSize: pageLimit,
      pageNumber,
      city: location?.value,
      'membership.name': selectedOption?.value,
      ...(metaDataId && { 'expertise.metaDataId': metaDataId }),
    };
    const res = await fetchUsersbyMatch(query as string, payload);

    if (!newSearch) {
      const filteredRes = res.data.filter(
        (obj1) => !userList.some((obj2) => obj1['id'] === obj2['id'])
      );
      setUserList([...userList, ...filteredRes]);
    } else {
      setUserList(res.data);
      setNewSearch(false);
    }
    if (!res.data.length) {
      setUserListCompleted(true);
    }
    setPageLoading(false);
    setLoading(false);
  };

  const fetchFollowings = async () => {
    setPageLoading(true);
    const res = await getFollowings(id as string, pageNumber, pageLimit);
    const userIds = res.data.map((pin: any) => pin.userId);
    if (userIds.length) {
      const userdata = await fetchUsers(userIds);
      if (pageNumber === 1) {
        setFollowingsList(userdata.data);
      } else if (pageNumber > 1) {
        setFollowingsList([...followingsList, ...userdata.data]);
      }
    }
    if (!res.data.length) {
      setFollowingListCompleted(true);
    }
    setPageLoading(false);
    setLoading(false);
  };

  const fetchFollowers = async () => {
    setPageLoading(true);
    const res = await getFollowers(id as string, pageNumber, pageLimit);

    const userIds = res.data.map((pin: any) => pin._userId);
    if (userIds.length) {
      const userdata = await fetchUsers(userIds);
      if (pageNumber === 1) {
        setFollowersList(userdata.data);
      } else if (pageNumber > 1) {
        setFollowersList([...followersList, ...userdata.data]);
      }
    }
    if (!res.data.length) {
      setFollowersListCompleted(true);
    }
    setPageLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    setPageNumber(1);
    setNewSearch(true);
    setUserListCompleted(false);
    setLoading(true);
    setDummyTrigger((num) => num + 1);
  }, [
    query,
    pageLimit,
    location,
    networkPage,
    refresh,
    selectedOption?.value,
    metaDataId,
  ]);

  const token = localStorage.getItem('token');

  const [signupModal, setSignupModal] = useState(false);

  useEffect(() => {
    if (
      (!networkPage && userListCompleted) ||
      (networkPage && isFollowers && followersListCompleted) ||
      (networkPage && !isFollowers && followingListCompleted)
    ) {
      return;
    }
    if (networkPage && isFollowers) {
      fetchFollowers();
    } else if (networkPage && !isFollowers) {
      fetchFollowings();
    } else {
      fetchUser();
    }
    setUserListCompleted(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dummyTrigger, id, isFollowers]);

  const fetchGeography = async () => {
    const payload: any = {};

    if (selectedOption?.value) {
      payload['membership.name'] = selectedOption.value;
    }

    if (metaDataId) {
      payload['expertise.metaDataId'] = metaDataId;
    }
    const cityArr = await fetchCities(payload);
    return cityArr.map((city: string) => ({ value: city, label: city }));
  };

  const fetchIntialGeography = async (searchTerm = '') => {
    const options = await fetchGeography();
    return options;
  };

  //@ts-ignore
  const handleChange = (value) => {
    setLocation(value);
  };

  const [isCategory, setIsCategory] = useState(false);
  const scrollPositionRef = useRef(0);
  const [isSlidingIn, setIsSlidingIn] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);

  const profileId = searchParams.get('profileId') || '';
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setCurrentUser(userRes);
    } catch (error) {
      console.error('Failed to fetch loggedIn user details:', error);
    }
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId);
    }
  }, [profileId]);

  useEffect(() => {
    if (isCategory) {
      setIsSlidingIn(true);
      setIsSlidingOut(false);
    } else if (!isCategory && isSlidingIn) {
      setIsSlidingOut(true);
      setTimeout(() => setIsCategory(false), 300); // Wait for the sliding-out animation to complete
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategory]);

  const handleClose = () => {
    setIsSlidingIn(false);
    setTimeout(() => setIsCategory(false), 300);
    window.scrollTo(0, scrollPositionRef.current);
  };

  const showCategory = () => {
    setIsCategory(true);
    if (window.innerWidth < 700) {
      scrollPositionRef.current = window.scrollY;
      window.scrollTo(0, 15);
    }
  };
  if (loading) {
    return <LoadingLottie height="150px" />;
  }

  return (
    <div className={styles['dashboard-list']}>
      {!networkPage && (
        <div className={styles['dashboard-filter-pagination']}>
          <div className={styles['dashboard-pagination']}>
            <Select
              value={selectedOption}
              onChange={(selected: any) =>
                setSelectedOption(selected as unknown as OptionType | null)
              }
              options={options}
              placeholder={isMobile ? ` Profession ` : `  Select Profession`}
              styles={isMobile ? mobileStyles : customStyles}
              isClearable={true}
            />

            <AsyncSelect
              loadOptions={fetchIntialGeography}
              value={location}
              defaultOptions
              styles={isMobile ? mobileStyles : customStyles}
              placeholder={isMobile ? ` Location` : `  Select City`}
              onChange={handleChange}
              isClearable={true}
              cacheOptions
            />
          </div>

          {window.innerWidth <= 700 && (
            <div>
              <Button
                onClick={() => {
                  showCategory();
                }}
                className={styles.userFilterButton}
              >
                <img
                  src="/images/filterIcon.png"
                  alt="filterIcon"
                  height={24}
                />
              </Button>
            </div>
          )}
        </div>
      )}
      {networkPage ? (
        <>
          {' '}
          <div className={styles['filter-wrapper']}>
            <div className={styles['trends-filter-hr']} />
            <div className={styles['trends-filter-label']}>
              Show results for
            </div>
            <div className={styles['trends-filter-btns']}>
              <button
                className={!isFollowers ? styles['active'] : ''}
                onClick={() => {
                  setIsFollowers(false);
                  setPageNumber(1);
                }}
              >
                Followings &nbsp;
                {profileId ? `(${currentUser?.followingCount})` : ''}
              </button>
              <button
                className={isFollowers ? styles['active'] : ''}
                onClick={() => {
                  setIsFollowers(true);
                  setPageNumber(1);
                }}
              >
                Followers &nbsp;
                {profileId ? `(${currentUser?.followerCount})` : ''}
              </button>
            </div>
          </div>
          {isFollowers ? (
            <div>
              {followersList.length ? (
                followersList.map((data, idx) => (
                  <SearchListItem
                    data={data}
                    key={`Search_List_Item_${data._id}`}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    networkPage
                  />
                ))
              ) : (
                <NoData />
              )}
            </div>
          ) : (
            <div>
              {followingsList.length ? (
                followingsList.map((data, idx) => (
                  <SearchListItem
                    data={data}
                    key={`Search_List_Item_${data._id}`}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    networkPage
                  />
                ))
              ) : (
                <NoData />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {userList.length ? (
            userList.map((data, idx) => (
              <SearchListItem
                data={data}
                key={`Search_List_Item_${data._id}`}
                setRefresh={setRefresh}
                refresh={refresh}
                networkPage
              />
            ))
          ) : (
            <NoData />
          )}
        </>
      )}

      {pageLoading ? (
        <div className={styles['load-more']}>
          <LoadingLottie height="150px" />
        </div>
      ) : networkPage && isFollowers && followersListCompleted ? (
        <>
          <div className={styles['load-more']}>
            "You're all caught up!" &nbsp;{' '}
            {window.scrollY !== 0 && (
              <Button
                className={styles['topScroll']}
                onClick={() => window.scrollTo(0, 0)}
              >
                Go to Top
              </Button>
            )}
          </div>
        </>
      ) : networkPage && !isFollowers && followingListCompleted ? (
        <>
          <div className={styles['load-more']}>
            "You're all caught up!" &nbsp;{' '}
            {window.scrollY !== 0 && (
              <Button
                className={styles['topScroll']}
                onClick={() => window.scrollTo(0, 0)}
              >
                Go to Top
              </Button>
            )}
          </div>
        </>
      ) : !networkPage && userListCompleted ? (
        <>
          <div className={styles['load-more']}>
            "You're all caught up!" &nbsp;{' '}
            {window.scrollY !== 0 && (
              <Button
                className={styles['topScroll']}
                onClick={() => window.scrollTo(0, 0)}
              >
                Go to Top
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className={styles['load-more']}>
          <Button
            color="transparent"
            className={styles.loadMoreBtn}
            onClick={() =>
              !token
                ? setSignupModal(true)
                : setPageNumber((prevPage) => prevPage + 1)
            }
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ color: '#1a1a1a' }}
            />
          </Button>
        </div>
      )}
      <div className={styles['prof-bottombar']}>
        <BottomBar />
      </div>
      {isCategory && (
        <Modal
          show={isCategory}
          onShow={() => {
            window.scrollTo(0, 15);
          }}
          onHide={handleClose}
          className={classNames(
            isSlidingIn
              ? styles['userFilterModalOpen']
              : isSlidingOut
              ? styles['userFilterModalClose']
              : styles['userFilterModal']
          )}
        >
          <ExpertiseFilterProfessional />
        </Modal>
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
};

export default SearchList;
