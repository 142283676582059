import { IMembership } from 'src/services/user-service/user-service.interface';

export const validateMembershipData = (membershipData: any) => {
  let newErrors: any = {};

  const errorMsgMemNumber = 'Please enter your Membership Number';
  if (membershipData.icai) {
    if (!membershipData.icai_membership_no) {
      newErrors.icai_membership_no = errorMsgMemNumber;
    }
  }
  if (membershipData.icsi) {
    if (!membershipData.icsi_membership_no) {
      newErrors.icsi_membership_no = errorMsgMemNumber;
    }
  }
  if (membershipData.icmai) {
    if (!membershipData.icmai_membership_no) {
      newErrors.icmai_membership_no = errorMsgMemNumber;
    }
  }
  if (membershipData.bci) {
    if (!membershipData.bci_membership_no) {
      newErrors.bci_membership_no = errorMsgMemNumber;
    }
  }

  return newErrors;
};

export const mapMembershipToData = (
  memberships: Array<IMembership>,
  name: string
) => {
  let data = {
    icai: false,
    icai_disabled: false,
    icai_name: name,
    icai_membership_no: '',
    icmai: false,
    icmai_disabled: false,
    icmai_name: name,
    icmai_membership_no: '',
    icsi: false,
    icsi_disabled: false,
    icsi_name: name,
    icsi_membership_no: '',
    bci: false,
    bci_disabled: false,
    bci_name: name,
    bci_membership_no: '',
    bci_certificate: '',
  };
  memberships.forEach((membership) => {
    if (membership.name === 'ICAI') {
      data.icai = true;
      data.icai_disabled = true;
      data.icai_membership_no = membership.number;
    }
    if (membership.name === 'ICMAI') {
      data.icmai = true;
      data.icmai_disabled = true;
      data.icmai_membership_no = membership.number;
    }
    if (membership.name === 'ICSI') {
      data.icsi = true;
      data.icsi_disabled = true;
      data.icsi_membership_no = membership.number;
    } else if (membership.name === 'BCI') {
      data.bci = true;
      data.bci_disabled = true;
      data.bci_membership_no = membership.number;
      if (membership.attachments?.[0]) {
        data.bci_certificate = membership.attachments?.[0] as any;
      }
    }
  });

  return data;
};
