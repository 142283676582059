import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { updateUserMembership } from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import { SuccessLottie } from '../lotties/success';

function MembershipModal({
  showModal,
  setShowMembershipModal,
  membership,
  setSelectedMembership,
  selectedUser,
  setSelectedUser,
  fetchData,
}) {
  const [status, setStatus] = useState('INITIATED');

  const handleClose = () => {
    setStatus('INITIATED');
    setShowMembershipModal(false);
    setSelectedMembership({});
    setSelectedUser({});
  };

  const handleMembershipVerification = async () => {
    setStatus('LOADING');
    await updateUserMembership(selectedUser.id, membership.id, {
      isVerified: true,
    });
    setTimeout(() => {
      setStatus('COMPLETED');
      fetchData();
    }, 2000);
    handleClose();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={status === 'INITIATED' ? handleClose : () => null}
      >
        <Modal.Header closeButton>
          <Modal.Title>Membership Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status === 'COMPLETED' && <SuccessLottie height={410} />}
          {status === 'LOADING' ? (
            <LoadingLottie height={410} />
          ) : (
            <div>
              Do you wish to verify membership of{' '}
              <strong>{membership.name}</strong> with{' '}
              <strong>{membership.number}</strong>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {status === 'INITIATED' ? (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleMembershipVerification}>
                Confirm
              </Button>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MembershipModal;
