import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styles from './secureAadhaarModal.module.css';

type Props = {
  securedModal: any;
  setSecuredModal: any;
};

const SecureAadhaarModal = ({ securedModal, setSecuredModal }: Props) => {
  return (
    <>
      <Modal
        isOpen={securedModal}
        centered
        toggle={() => setSecuredModal(!false)}
      >
        <ModalBody className={styles['secured-aadhaar-modalBody']}>
          <div className={styles['modalBody-wrapper']}>
            <div className={styles['modalBody-image-container']}>
              <div className={styles['aadhaar-image-container']}>
                <img src="/images/aadhaar_logo.png" alt="aadhaarLogo" />
              </div>
              <div className={styles['signzy-uidai-container']}>
                <div className={styles['signzy-image-container']}>
                  <img src="/images/signzy_logo.png" alt="signzyLogo" />
                </div>
                <div className={styles['arrow-image-container']}>
                  <img src="/images/double_arrow.png" alt="arrowimg" />
                </div>

                <div className={styles['uidai-container']}>
                  UIDAI Encryption
                </div>
              </div>
              <div className={styles['wcpLogo-image-container']}>
                <img src="/images/wcp-logo-text.svg" alt="wcpLogo" />
              </div>
            </div>
            <div className={styles['text-desc-container']}>
              <b> White Collar Professional</b> , collects the information from
              your Aadhar such as name, location, and profile picture; which is
              safe and secure through the <b>UIDAl Encryption</b> Channel.
              <br /> <br />
              All the information collected is not shared with anyone, within or
              outside the organization for any purpose so defined.
            </div>
            <div className={styles['submit-button-wrapper']}>
              <button
                className={styles['submit-button']}
                onClick={() => {
                  setSecuredModal(false);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SecureAadhaarModal;
