export const resolveBEAsset = (url: string) => {
    return process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_API_HOST}${url}` : url
}

export const fileToDataUrl = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
            const reader = new FileReader();

            reader.addEventListener(
                "load",
                () => {
                    resolve(reader.result as string)
                },
                false,
            );

            reader.addEventListener(
                "error",
                (error) => {
                    reject(error)
                }
            );

            reader.readAsDataURL(file);
        } else {
            reject("Invalid file type")
        }
    })
}


export const dataUrlToFile = (url: string, fileName = "profilePic.jpg") => {
    return new Promise<File>(async (resolve, reject) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            const file = new File(
                [blob],
                fileName,
                {
                    type: "image/jpeg",
                    lastModified: new Date().valueOf()
                }
            );

            resolve(file)
        } catch (e) {
            reject(e)
        }
    })

}