import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../navbar/navbar.module.css';

import { Dropdown } from 'react-bootstrap';
import { fetchTrendSearch } from 'src/services/trend-service/trend-service';
import classNames from 'classnames';

export default function TrendSearch({
  trendName = '',
  setTrendName,
  isTrendQuery,
  setIsTrendQuery,
}: any) {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleTrendNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTrendName(event.target.value);
  };

  const handleKeyPressEvent = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' || event.key === 'Return') {
      urlParams.set('trendName', `${trendName}`);
      navigate(`/trending?${urlParams.toString()}`);
      setOptions([]);
      handleCloseDropdown();
    }
  };

  const [options, setOptions] = useState<any[]>([]);
  const getEventsByName = async () => {
    if (trendName.length > 0) {
      const res = await fetchTrendSearch(trendName);
      setOptions(res.data);
    }
  };

  const handleOptionClick = (id: any) => {
    urlParams.set('trendId', id);
    navigate(`/trending?${urlParams.toString()}`);
    setTrendName('');
    setOptions([]);
    handleCloseDropdown();
  };

  const handleCloseDropdown = () => {
    if (ref.current) {
      setIsTrendQuery(false);
      setOptions([]);
    }
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleCloseDropdown();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsTrendQuery]);

  useEffect(() => {
    getEventsByName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendName]);

  return (
    <div ref={ref}>
      <div
        className={classNames(
          window.innerWidth > 700 && isTrendQuery
            ? styles['wc-search-container-active']
            : window.innerWidth > 700 && !isTrendQuery
            ? styles['wc-search-container']
            : styles['wc-search-container']
        )}
      >
        {' '}
        <div
          className={classNames(
            styles['wc-search'],
            isTrendQuery && styles['wc-search-paddingActive'],
            options.length > 0 && isTrendQuery && styles['wc-search-active']
          )}
        >
          {window.innerWidth < 700 ? (
            <div className={styles['search-user-img-mobile']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                width={16}
                height={19}
              />
            </div>
          ) : (
            <div className={styles['search-user-img-windows']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                height={20}
              />
            </div>
          )}
          <input
            type="text"
            ref={inputRef}
            value={trendName}
            onChange={handleTrendNameChange}
            placeholder=" Search for Trending items"
            className={styles['wc-search-input']}
            onKeyDown={handleKeyPressEvent}
            onFocus={() => setIsTrendQuery(true)}
            enterKeyHint="go"
          />{' '}
          {trendName.length > 0 && (
            <div className={styles.navClearBtnWrapper}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                onClick={() => {
                  setTrendName('');
                  navigate('/trending');
                  handleCloseDropdown();
                }}
              />
            </div>
          )}
        </div>
        {isTrendQuery && options.length > 0 && (
          <Dropdown.Menu
            show
            className={styles.navbarSeacrhDropdown}
            style={{
              width: ref?.current?.offsetWidth,
              maxWidth: ref?.current?.offsetWidth,
            }}
          >
            {options.map((option) => (
              <Dropdown.Item
                dangerouslySetInnerHTML={{ __html: option.content }}
                style={{
                  padding: '8px 12px ',
                  borderBottom: '1px solid #ccc',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                key={option.value}
                className={styles['dropdown-option']}
                onClick={() => {
                  handleOptionClick(option._id);
                }}
              />
            ))}
          </Dropdown.Menu>
        )}
      </div>
    </div>
  );
}
