import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './app-pop-up.module.css';
import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
interface AppPopUpProps {
  bottom?: string;
}
export default function AppPopUp({ bottom = '60px' }: AppPopUpProps) {
  const [downloadApp, setDownloadApp] = useState(false);
  const [showAppPopUp, setShowAppPopUp] = useState(true);
  const appRunning = localStorage.getItem('isApp') || '';
  if (appRunning === 'true') {
    return null;
  }
  if (!showAppPopUp) {
    return null;
  }
  return (
    <div className={styles['app-popup-parent']} style={{ bottom: bottom }}>
      <div className={styles['app-popup-wrapper']}>
        <div className={styles['app-popup-image-container']}>
          <img src="/images/wcp-phone-popup.png" alt="wcpPhoneLogo" />
        </div>
        <div className={styles['app-popup-text-container']}>
          <div className={styles['app-popup-text-first']}>
            Get full access to WCP - Community Platform.
          </div>
          <div
            onClick={() => {
              setDownloadApp(true);
            }}
            className={styles['app-popup-text-second']}
          >
            Use the Mobile App
          </div>
        </div>
        <div className={styles['app-popup-closebtn-warpper']}>
          <FontAwesomeIcon
            icon={faX}
            style={{ fontSize: '12px', color: '#e6e6e6' }}
            onClick={() => {
              setShowAppPopUp(false);
            }}
          />
        </div>
      </div>
      {downloadApp && (
        <>
          <Modal isOpen={downloadApp} centered>
            <ModalBody className={styles['downloadApp-modal']}>
              <div>
                <div className={styles['logo-img-wrapper']}>
                  <img src="/images/logo-black.svg" alt="wcplogo" />
                </div>
                <div className={styles['logo-subtitle']}>
                  WCP - Community Platform
                </div>
                <div className={styles['downloadNow-wrapper']}>
                  Download Now !
                </div>
                <div className={styles['appstore-icon-wrapper']}>
                  <img
                    src="/images/playstore-icon.png"
                    alt="playstroreLogo"
                    onClick={() => {
                      window.location.href =
                        'https://play.google.com/store/apps/details?id=com.wcp_community.wcpapp';
                    }}
                    width={200}
                  />
                  <img
                    src="/images/appstore-icon.png"
                    alt="appstoreLogo"
                    onClick={() => {
                      window.location.href =
                        'https://apps.apple.com/in/app/white-collar-professional/id6478199970';
                    }}
                    width={203}
                    height={63}
                  />
                </div>
                <div className={styles['closeButton-modal']}>
                  <Button
                    color="dark"
                    outline
                    size="lg"
                    onClick={() => {
                      setDownloadApp(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
}
