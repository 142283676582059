import Lottie from 'react-lottie';
import loadingJson from './loading.json';

type Props = {
  height?: number | string;
};

export const LoadingLottie = ({ height }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} />
    </div>
  );
};
