import React, { useContext, useEffect, useState } from 'react';
import ChatListMobile from 'src/components/message/ChatListMobile';
import ChatWindowMobile from 'src/components/message/ChatWindowMobile';
import SignupModal from 'src/components/stepper/SignupModal';
import { MessageContext } from 'src/context/message.context';
import Layout from 'src/layouts/layout';
import { fetchRecentChatList } from 'src/services/message-service/message-service';

const MessageMobile: React.FC = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [openChatList, setOpenChatList] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectedFriend } = useContext(MessageContext);
  const token = localStorage.getItem('token');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [fetchedRecentList, setFechedRecentList] = useState<any>([]);
  const [signupModal, setSignupModal] = useState(false);

  useEffect(() => {
    if (!token) {
      setSignupModal(true);
    } else {
      fetchRecentChat();
    }
  }, []);

  const fetchRecentChat = async () => {
    const user: any = localStorage.getItem('user');

    if (!token || !user) {
      return;
    }
    if (user) {
      const userData = JSON.parse(user);
      try {
        const recentChatList = await fetchRecentChatList(userData._id);
        setFechedRecentList(recentChatList?.data);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const handleRefreshClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await fetchRecentChat();
  };

  return (
    <>
      {selectedFriend.length === 0 && (
        <Layout contentBordered>
          <div>
            <ChatListMobile refreshList={fetchedRecentList} />
          </div>
        </Layout>
      )}

      {selectedFriend.map((selData: any) => (
        <ChatWindowMobile
          key={selData?.user?._id}
          messageListData={selData}
          handleRefreshClick={handleRefreshClick}
        />
      ))}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default MessageMobile;
