import React from 'react';
import { Button } from 'reactstrap';
import HomeIcon from 'src/pages/home/home';
import { useNavigate } from 'react-router-dom';
const SignupNavbar = () => {
  const router = useNavigate();
  const navigateToHome = () => {
    router('/');
  };
  const navigateToLogin = () => {
    router('/basesignup');
  };
  return (
    <div className="signup-navbar">
      <Button
        color="transparent"
        onClick={() => navigateToHome()}
        // className="home-btn"
      >
        <img src={HomeIcon} alt="" />
        Home
      </Button>
      <Button color="transparent" onClick={() => navigateToLogin()}>
        Back
      </Button>
    </div>
  );
};

export default SignupNavbar;
