import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardList from 'src/components/dashboard/dashbaordList';
import AchievementSkills from 'src/components/profile/achievementsSkills';
import Expertise from 'src/components/profile/expertise';
import JobsOccupation from 'src/components/profile/jobsOccupation';
import Membership from 'src/components/profile/membership';
import MembershipHeader from 'src/components/profile/MembershipHeader';
import Layout from 'src/layouts/layout';
import { IUser } from 'src/services/user-service/user-service.interface';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import Result from '../question/question';
import SearchList from 'src/components/search/searchList';
import styles from './profile.module.css';
import classNames from 'classnames';

const Profile = () => {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const profileId = query.get('profileId');
  const view = query.get('view');
  const postId = query.get('postId');

  const [user, setUser] = useState<IUser>();

  const getUserDetails = async (userId: string) => {
    const userRes = await fetchUserDetails(userId);
    setUser(userRes);
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId as string);
    }
  }, [profileId, view]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles['profile-parent']}>
      {view ? (
        postId ? (
          <Layout contentBordered>
            <div className={classNames('wc-profile', styles['viewPostPage'])}>
              <Result profilePage />
            </div>
          </Layout>
        ) : view !== 'networks' ? (
          <Layout contentBordered>
            <div className="wc-profile">
              <DashboardList profilePage />
            </div>
          </Layout>
        ) : (
          <Layout contentBordered>
            <div className="wc-profile">
              <SearchList networkPage />
            </div>
          </Layout>
        )
      ) : (
        <>
          {!isMobile ? (
            <Layout contentBordered>
              <div className={styles['wc-profile']}>
                <div className={styles['membership-header-wrapper']}>
                  <MembershipHeader />
                </div>
                <Membership user={user as IUser} />
                <Expertise user={user as IUser} />
                <AchievementSkills user={user as IUser} />
                <JobsOccupation user={user as IUser} />
              </div>
            </Layout>
          ) : (
            <div className={styles['wc-profile-mobile']}>
              <div className={styles['membership-header-wrapper']}>
                <MembershipHeader />
              </div>

              <div>
                <Membership user={user as IUser} />
                <Expertise user={user as IUser} />
                <AchievementSkills user={user as IUser} />
                <JobsOccupation user={user as IUser} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
