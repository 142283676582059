import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from 'src/layouts/layout';

const AppNotification = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role === 'USER') {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');

  const handlePushNotification = () => {
    if (message.length > 0 && title.length > 0) {
      // try {
      //   axios.post(`https://app.nativenotify.com/api/notification`, {
      //     appId: process.env.APP_ID,
      //     appToken: process.env.APP_TOKEN,
      //     title: `${title}`,
      //     body: `${message}`,
      //     pushData: `{"url":"${url}"}`,
      //   });
      //   setMessage('');
      // } catch (error) {
      //   console.log('Error in Pushing NotiFication', error);
      // }
    }
  };

  return (
    <Layout contentBordered={true}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          margin: '30px auto',
        }}
      >
        <input
          type="text"
          id="message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          placeholder="Enter your notification message here"
          required
        />
        <input
          type="text"
          id="title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          placeholder="Enter your title here"
          required
        />
        <input
          type="text"
          id="url"
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          placeholder="Enter your url here"
          required
        />
        <button onClick={handlePushNotification}> Push NotiFication</button>
      </div>
    </Layout>
  );
};

export default AppNotification;
