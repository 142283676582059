import React, { useEffect, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';

import Layout from 'src/layouts/layout';

import styles from './hiringJobs.module.css';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateJob from './CreateJob';
import BottomBar from '../bottombar/BottomBar';
import SignupModal from '../stepper/SignupModal';
import { LoadingLottie } from '../lotties/loading';
import { useSearchParams } from 'react-router-dom';
import AppPopUp from '../bottombar/AppPopUp';
import { Button } from 'reactstrap';
import HiringJobsFilter from './HiringJobsFilter';
import { Modal } from 'react-bootstrap';
import {
  fetchJobs,
  getAllQualificationLocation,
} from 'src/services/job-service/job-service';
import classNames from 'classnames';
import HiringJobsListItem from './HiringJobsListItem';

export interface SubmittedJobData {
  jobName: string;
  jobUrl: string;
  content: string;
  jobImage: string;
  _id: string;
  _userId: string;
  jobQualification: string;
  jobLocation: string;
  jobOpening: string;
  jobExperience: string;
  jobAmount: string;
  jobSkills: string;
  jobType: string;
  jobTenure: string;
  jobContactName: string;
  jobContactNumber: string;
  jobContactEmail: string;
  jobDeadLine: string;
  createdOn: string;
  plainContent: string;
  userName?: string;
  jobId: string;
}

interface OptionType {
  value: string;
  label: string;
}

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: '1.5rem',
    width: `${
      window.innerWidth > 1000 && window.innerWidth <= 1200
        ? '180px'
        : window.innerWidth > 1200
        ? '250px'
        : '150px'
    }`,
    border: '1px solid #1a1a1a',
    fontWeight: 'bold',
    color: 'black',
    height: '12px',
    padding: ' 2px 0.25rem 0 ',

    '&:hover': {
      border: '1px solid #1a1a1a',
      fontWeight: 'bold',
      color: 'black',
    },
  }),
  input: (provided) => ({
    ...provided,

    height: '100%',
    margin: 'auto 0.25rem ',
    fontSize: '14px',
  }),
  placeholder: (provided) => ({
    ...provided,
    height: '100%',
    margin: 'auto 0.25rem ',
    fontSize: '14px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 500,
  }),
};

const mobileStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: '24px',
    padding: '0 8px',
    border: '1px solid #333',
    fontWeight: 'bold',
    color: 'black',
    width: '160px',
    minHeight: '20px',
    fontSize: '12px',

    '&:hover': {
      border: '1px solid #1a1a1a',
      fontWeight: 'bold',
      color: 'black',
    },
  }),
  input: (provided) => ({
    ...provided,

    margin: '2px 8px',

    fontSize: '12px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 ',
    width: 'max-content',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 ',
    div: { padding: '4px 3.5px' },
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: '8px',
    marginRight: 0,
    fontSize: '12px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    svg: { height: '11px', width: '11px' },
    color: 'black',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
};
export const formatToIndianCurrency = (amount: number | string): string => {
  const number = typeof amount === 'string' ? parseFloat(amount) : amount;
  return new Intl.NumberFormat('en-IN').format(number);
};
const HiringJobs: React.FC = () => {
  const [jobs, setJobs] = useState<SubmittedJobData[]>([]);

  const [selectedQualification, setSelectedQualification] =
    useState<OptionType | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<OptionType | null>(
    null
  );
  const [qualifications, setQualifications] = useState<OptionType[]>([]);
  const [locations, setLocations] = useState<OptionType[]>([]);
  const [searchParams] = useSearchParams();
  const token = localStorage.getItem('token') || '';
  const [signupModal, setSignupModal] = useState(false);
  const jobId = searchParams.get('jobId') || '';
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [jobsCompleted, setJobsCompleted] = useState<boolean>(false);
  const [dummyTrigger, setDummyTrigger] = useState<number>(1);
  const companyName = searchParams.get('jobsName') || '';
  const jobMode = searchParams.get('jobMode') || '';
  const jobExperience = searchParams.get('jobExperience') || '';
  const jobType = searchParams.get('jobType') || '';
  const jobTenure = searchParams.get('jobTenure') || '';

  const fetchData = async () => {
    try {
      const pageSize = 20;
      const filters = {
        qualification: selectedQualification?.value,
        location: selectedLocation?.value,
        jobName: companyName,
        jobMode: jobMode,
        jobExperience: jobExperience,
        jobType: jobType,
        jobTenure: jobTenure,
      };
      setLoading(true);
      const jobData = await fetchJobs(pageNumber, pageSize, jobId, filters);

      if (!jobData.data.length) {
        setJobsCompleted(true);
      }

      const jobsWithUsernames = await Promise.all(
        jobData?.data.map(async (job: any) => {
          return {
            _id: job._id,
            jobName: job?.title,
            jobUrl: job?.link,
            content: job?.content,
            jobImage: job?.attachments[0]?.path,
            _userId: job?._userId as unknown as string,
            jobQualification: job?.jobQualification,
            jobLocation: job?.jobLocation,
            jobOpening: job?.jobOpening,
            jobExperience: job?.jobExperience,
            jobAmount: job?.jobAmount,
            jobSkills: job?.jobSkills,
            jobType: job?.jobType,
            jobTenure: job?.jobTenure,
            jobContactName: job?.jobContactName,
            jobContactNumber: job?.jobContactNumber,
            jobContactEmail: job?.jobContactEmail,
            jobDeadLine: job?.endedOn,
            createdOn: job?.createdOn,
            plainContent: job?.plainContent,
            userName: job?.jobUserName,
            jobId: job?.jobId,
          };
        })
      );

      if (pageNumber === 1) {
        setJobs(jobsWithUsernames);
      } else {
        setJobs((prevJobs) => [...prevJobs, ...jobsWithUsernames]);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setLoading(false); // Ensure loading state is reset on error
    }
  };
  const scrollPositionRef = useRef(0);
  const getLatestLocation = async () => {
    const allQualificationLocation = await getAllQualificationLocation(
      selectedQualification
    );
    const qualificationResponse = allQualificationLocation?.jobQualification;

    const locationResponse = allQualificationLocation?.jobLocation;

    setQualifications(
      qualificationResponse.map((qualification: any) => ({
        value: qualification,
        label: qualification,
      }))
    );

    setLocations(
      locationResponse.map((location: any) => ({
        value: location,
        label: location,
      }))
    );
  };
  useEffect(() => {
    fetchData(); // Reset jobs on filter change
    getLatestLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedQualification,
    selectedLocation,
    pageNumber,
    companyName,
    jobMode,
    jobExperience,
    jobType,
    jobTenure,
    jobId,
  ]);

  useEffect(() => {
    setPageNumber(1);
    setJobsCompleted(false);
    setJobs([]);
    setDummyTrigger(dummyTrigger + 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedQualification,
    selectedLocation,
    companyName,
    jobMode,
    jobExperience,
    jobType,
    jobTenure,
  ]);

  const handleQualificationChange = (newValue: unknown) => {
    setSelectedQualification(newValue as OptionType | null);
  };

  const handleLocationChange = (newValue: unknown) => {
    setSelectedLocation(newValue as OptionType | null);
  };

  const [jobFilterModal, setJobFilterModal] = useState(false);
  const [isSlidingIn, setIsSlidingIn] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);
  useEffect(() => {
    if (jobFilterModal) {
      setIsSlidingIn(true);
      setIsSlidingOut(false);
    } else if (!jobFilterModal && isSlidingIn) {
      setIsSlidingOut(true);
      setTimeout(() => setJobFilterModal(false), 300); // Wait for the sliding-out animation to complete
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobFilterModal]);

  const handleClose = () => {
    setIsSlidingIn(false);
    setTimeout(() => setJobFilterModal(false), 300);
    window.scrollTo(0, scrollPositionRef.current);
  };

  return (
    <Layout contentBordered={false}>
      <div className={styles['jobs-parent']}>
        {window.innerWidth <= 700 && <CreateJob />}
        <div className={styles['filters']}>
          <Select
            options={qualifications}
            value={selectedQualification}
            styles={window.innerWidth >= 700 ? customStyles : mobileStyles}
            placeholder="Qualification"
            onChange={handleQualificationChange}
            isSearchable={true}
            isClearable={true}
          />
          <AsyncSelect
            value={selectedLocation}
            defaultOptions={locations}
            styles={window.innerWidth >= 700 ? customStyles : mobileStyles}
            placeholder="Location"
            onChange={handleLocationChange}
            isClearable={true}
            cacheOptions
          />
        </div>

        {jobs
          .sort(
            (a, b) =>
              new Date(b?.createdOn).getTime() - new Date(a.createdOn).getTime()
          )
          .map((job, index) => (
            <HiringJobsListItem job={job} />
          ))}

        {jobId ? null : loading ? (
          <LoadingLottie height="150px" />
        ) : jobsCompleted ? (
          <>
            <div className={styles['load-more']}>
              {jobs.length === 0
                ? 'There are No Jobs to display'
                : "You're all caught up!"}
              &nbsp;
              {window.scrollY !== 0 && (
                <Button
                  className={styles['topScroll']}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Go to Top
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className={styles['load-more']}>
            <Button
              color="transparent"
              className={styles.loadMoreBtn}
              onClick={() =>
                !token
                  ? setSignupModal(true)
                  : setPageNumber((prevPage) => prevPage + 1)
              }
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ color: '#1a1a1a' }}
              />
            </Button>
          </div>
        )}
      </div>

      {window.innerWidth < 700 && (
        <Button
          className={styles['create-job-mobile-btn']}
          onClick={() => {
            setJobFilterModal(true);
            if (window.innerWidth < 700) {
              scrollPositionRef.current = window.scrollY;
              window.scrollTo(0, 5);
            }
          }}
        >
          <img src="/images/filterIcon.png" alt="filterIcon" height={24} />
        </Button>
      )}
      {window.innerWidth < 700 && <AppPopUp />}
      {window.innerWidth <= 700 && <BottomBar />}

      <Modal
        show={jobFilterModal}
        onHide={handleClose}
        className={classNames(
          isSlidingIn
            ? styles['jobFilterModalOpen']
            : isSlidingOut
            ? styles['jobFilterModalClose']
            : styles['jobFilterModal']
        )}
      >
        <HiringJobsFilter />
      </Modal>

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </Layout>
  );
};

export default HiringJobs;
