import { ReactElement } from 'react';

import styles from './text-image.module.css';

type Props = {
  title: string;
  desc: any;
  imageComp: ReactElement;
  flexDirection: 'row' | 'row-reverse';
};

export default function TextImage({
  title = 'Title',
  desc = 'Description',
  imageComp,
  flexDirection = 'row',
}: Props) {
  return (
    <>
      <div className={styles.wrapper} style={{ flexDirection }}>
        <div className={styles.textWrapper}>
          <h2 className={styles.textTitle}>{title}</h2>
          <body className={styles.textDesc}>{desc}</body>
        </div>
        <div className={styles.imageWrapper}>{imageComp}</div>
      </div>
      <div className={styles.wrapperMobile}>
        <div className={styles.textTitle}>{title}</div>
        <div className={styles.imageWrapper}>{imageComp}</div>
        <div className={styles.textDesc}>{desc}</div>
      </div>
    </>
  );
}
