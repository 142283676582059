import { IMetaData } from 'src/services/meta-data-service/meta-data-interface';
import { IMenuList } from './sidebar.interface';

export const formatMenu = (menu: IMetaData[]): IMenuList[] => {
  const formattedMenu: IMenuList[] = menu.map((metadata) => {
    const subMenu: IMenuList[] = metadata.subMetaDatas.map((subMenu) => ({
      id: subMenu.id,
      title: subMenu.name,
    }));

    return {
      id: metadata.id,
      title: metadata.name,
      icon: metadata.icon,
      children: subMenu,
    };
  });

  return formattedMenu;
};
