import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import styles from './navbar.module.css';
import { Link } from 'react-router-dom';
import { getUserNotifications } from 'src/services/notification-service/notification-service';
import classNames from 'classnames';
import {
  IUserNotification,
  IUserNotificationResponse,
} from 'src/services/notification-service/notification-interface';
import { LoadingLottie } from '../lotties/loading';
import { resolveBEAsset } from 'src/utils/urls';
import SignupModal from '../stepper/SignupModal';

export default function NotificationButton() {
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [userNotifications, setUserNotifications] = useState<
    IUserNotification[]
  >([]);
  const [signupModal, setSignupModal] = useState(false);
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id') || '';
  const fetchUserNotifications = async () => {
    setLoader(true);
    let res: IUserNotificationResponse = {
      data: [],
      total: 0,
      pageSize: 0,
      pageNumber: 0,
    };
    if (id) {
      res = await getUserNotifications(id);
    }
    setUserNotifications(res.data);
    setLoader(false);
  };

  useEffect(() => {
    if (showSettings) {
      fetchUserNotifications();
    }
    return () => {
      setUserNotifications([]);
    };
  }, [showSettings]);
  useEffect(() => {
    setIsNotificationOpened(showSettings);
  }, [showSettings]);

  const formatUserNotification = (noti: IUserNotification) => {
    switch (noti.type) {
      case 'POST':
        return (
          <>
            <b>{noti?.userId?.name}</b> has posted an update
          </>
        );
      case 'COMMENT':
        return (
          <>
            <b>{noti?.userId?.name}</b> has commeted on your post
          </>
        );
      case 'REPLY':
        return (
          <>
            <b>{noti?.userId?.name}</b> has replied in your post
          </>
        );
      case 'POST_UPVOTE':
        return (
          <>
            <b>{noti?.userId?.name}</b> has upvoted in your post
          </>
        );
      case 'COMMENT_UPVOTE':
        return (
          <>
            <b>{noti?.userId?.name}</b> has upvoted your comment
          </>
        );
      case 'REPLY_UPVOTE':
        return (
          <>
            <b>{noti?.userId?.name}</b> has upvoted your reply
          </>
        );
      case 'TREND_UPVOTE':
        return (
          <>
            <b>{noti?.userId?.name}</b> has upvoted your Trending item
          </>
        );
      case 'EVENT_UPVOTE':
        return (
          <>
            <b>{noti?.userId?.name}</b> has shown interest in your event.
          </>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <Dropdown
        className={classNames(styles.profileContextMenuNoti)}
        isOpen={showSettings && !signupModal}
        style={{ marginTop: '8px' }}
        toggle={
          !token
            ? () => setSignupModal(true)
            : () => setShowSettings((old) => !old)
        }
        direction="down"
        onClose={() => setIsNotificationOpened(false)}
      >
        <DropdownToggle>
          <Button
            color="transparent"
            onClick={() => {
              if (!token) {
                setSignupModal(true);
                setIsNotificationOpened(false);
              } else {
                setIsNotificationOpened(true);
              }
            }}
            className={classNames(styles['notiButtonTextWrapper'])}
            style={{ marginTop: '-6px' }}
          >
            <img
              src={
                isNotificationOpened
                  ? '/images/notiIconOpened.svg'
                  : '/images/notiIcon.svg'
              }
              alt="notiIcon"
              style={{ marginBottom: '6px', height: '18px' }}
              className={classNames(
                styles['notiDropdownBtnImg'],
                isNotificationOpened && styles['notiDropDownOpenImg']
              )}
            />
            <p
              className={classNames(
                styles.notiButtonText,
                isNotificationOpened && styles['notiDropDownOpenImg']
              )}
            >
              Notifications
            </p>
          </Button>
        </DropdownToggle>

        <DropdownMenu>
          <div
            style={{ height: '400px', minHeight: '400px', overflow: 'auto' }}
          >
            {loader ? <LoadingLottie height="200px" /> : null}

            {userNotifications.map((noti) => (
              <DropdownItem key={noti._id}>
                <Link
                  to={
                    noti?.type === 'TREND_UPVOTE'
                      ? `/trending?trendId=${noti?._trendId}`
                      : noti?.type === 'EVENT_UPVOTE'
                      ? `/events?eventId=${noti?._eventId}`
                      : `/post?postId=${noti?._postId}`
                  }
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className={styles['noti-row']}>
                    <div>
                      <img
                        src={
                          noti.userId?.picture?.path
                            ? resolveBEAsset(noti.userId?.picture?.path || '')
                            : '/images/defaultProfilePic.svg'
                        }
                        alt="user profile"
                        className={styles['avatar-img-mid']}
                        width={0}
                        height={0}
                      />
                    </div>
                    <div>{formatUserNotification(noti)}</div>
                  </div>
                </Link>
              </DropdownItem>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
}
