import { Fragment, useContext, useEffect, useState } from 'react';
import { Accordion, AccordionHeader, AccordionItem, Button } from 'reactstrap';
import ProfileSidebar from './profileSidebar';
import { MenuContext } from 'src/context/menu.context';
import { formatMenu } from './sidebar.helper';
import { IMenuList } from './sidebar.interface';
import styles from './sidebar.module.css';
import { faCaretDown, faDiamond } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { resolveBEAsset } from 'src/utils/urls';

import CreateSignatureModal from '../createSignature/CreateSignatureModal';
import CategoryRequestSentModal from '../createSignature/CategoryRequestSentModal';
import { SEARCH_TYPE } from 'src/config/enums';

import SignupModal from '../stepper/SignupModal';
import MembershipHeader from '../profile/MembershipHeader';
import CreateEvent from '../events/CreateEvent';
import EventLogo from '../events/EventLogo';
import CreateJob from '../hiringJobs/CreateJob';
import HiringJobsFilter from '../hiringJobs/HiringJobsFilter';
import InviteProfessional from '../search/searchList/InviteProfessional';
import { CreateTrend } from 'src/pages/trend/CreateTrend';
import ExpertiseFilterProfessioanl from '../search/searchList/expertiseFilter';

const Sidebar = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isCategoryRequestSentModalOpen, setCategoryRequestSentModalOpen] =
    useState(false);
  const currentUrl = window.location.href;
  const [menuList, setMenuList] = useState<IMenuList[]>([]);
  const { menu } = useContext(MenuContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);

  const selectedMetaDataId = searchParams.get('metaDataId');
  const [isFilterDropdownShow, setIsFilterDropdownShow] = useState(true);

  const [open, setOpen] = useState('');
  const handleMenuSelect = (
    id: string,
    key: 'metaDataId' | 'subMetaDataId' = 'metaDataId'
  ): void => {
    const urlParams = new URLSearchParams(searchParams);
    urlParams.delete('metaDataId');
    urlParams.delete('subMetaDataId');
    if (selectedMetaDataId?.includes(id)) {
      urlParams.delete('metaDataId');
    } else {
      urlParams.set(key, id);
    }
    if (currentUrl.includes('post') || currentUrl.includes('events')) {
      urlParams.delete('postId');

      urlParams.delete('query');
      if (urlParams.get('searchType') === SEARCH_TYPE.PROFESSIONAL) {
        navigate({
          pathname: '/search?searchType=Professional',
          search: createSearchParams(urlParams).toString(),
        });
      } else {
        navigate({
          pathname: '/dashboard',
          search: createSearchParams(urlParams).toString(),
        });
      }
    }

    navigate({
      search: createSearchParams(urlParams).toString(),
    });
  };

  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const handleCreateSignature = () => {
    setCreateModalOpen(true);
  };

  const handleCreateSignatureSuccess = () => {
    setCreateModalOpen(false);
    setCategoryRequestSentModalOpen(true);
  };

  const handleFilterDropdowShow = () => {
    setIsFilterDropdownShow((prev) => !prev);
  };
  const urlParams = new URLSearchParams(searchParams);
  const handlePinNotification = () => {
    if (currentUrl.includes('view=pins-noti')) {
      navigate('/notification');
    } else {
      urlParams.set('view', 'pins-noti');
      navigate(`/notification?${urlParams.toString()}`);
    }
  };

  useEffect(() => {
    if (menu?.length) {
      setMenuList(formatMenu(menu));
    }
  }, [menu]);

  return (
    <div className={styles['wc-sidebar']}>
      {currentUrl.includes('post') ? null : currentUrl.includes('profile') ? (
        <ProfileSidebar />
      ) : (
        <>
          {!currentUrl.includes('/notification') && <MembershipHeader />}
          {currentUrl.includes('/notification') && token && (
            <div className={styles['sidebar-option']}>
              <Button
                onClick={handlePinNotification}
                className={styles['create-sign-btn']}
              >
                <img
                  src={'/images/pinnedIcon.png'}
                  alt="pinIcon - team WCP"
                  height={14}
                />
                &nbsp; Pinned Updates
              </Button>
            </div>
          )}
          {currentUrl.includes('/search?searchType=Professional') && (
            <InviteProfessional />
          )}

          {!currentUrl.includes('events') &&
            !currentUrl.includes('/jobs') &&
            !currentUrl.includes('/trending') &&
            !currentUrl.includes('/search?searchType=Professional') && (
              <Fragment>
                {currentUrl.includes('dashboard') && (
                  <div>
                    <div className={styles['sidebar-option']}>
                      <Button
                        className={styles['create-sign-btn']}
                        onClick={() => {
                          !token
                            ? setSignupModal(true)
                            : handleCreateSignature();
                        }}
                      >
                        <EventLogo fill="#000" />
                        &nbsp; Create New Category
                      </Button>
                    </div>
                  </div>
                )}

                <div className={styles['filter-btn-wrapper']}>
                  <Button onClick={handleFilterDropdowShow}>
                    Filter By Category &nbsp; &nbsp;
                    {!isFilterDropdownShow && (
                      <FontAwesomeIcon icon={faCaretDown} rotation={270} />
                    )}
                    {isFilterDropdownShow && (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </Button>
                </div>

                {isFilterDropdownShow && (
                  <div className={styles['dashboard-sidebar-wrapper']}>
                    <div className={styles['sidebar-list']}>
                      {menuList.map((item, index) => (
                        <Accordion
                          className={styles['wc-accordion']}
                          open={open}
                          // @ts-ignore
                          toggle={toggle}
                          key={`item_${index}`}
                        >
                          <AccordionItem
                            className={styles['wc-accordion-item']}
                          >
                            <AccordionHeader
                              onClick={() =>
                                item.children?.length === 0 &&
                                handleMenuSelect(item.id)
                              }
                              className={styles['wc-accordion-header']}
                              targetId={`item_${index}`}
                            >
                              {item.icon && (
                                <img
                                  src={resolveBEAsset(item.icon.path)}
                                  className={styles['list-icon']}
                                  alt="Icon"
                                />
                              )}
                              {item.title}
                              {selectedMetaDataId === item.id && (
                                <span className={styles.floatingIcon}>
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faDiamond}
                                    style={{ height: '10px', width: '!0px' }}
                                  />
                                </span>
                              )}
                            </AccordionHeader>
                          </AccordionItem>
                        </Accordion>
                      ))}
                    </div>
                  </div>
                )}

                <CreateSignatureModal
                  isOpen={isCreateModalOpen}
                  onClose={() => setCreateModalOpen(false)}
                  onCreate={handleCreateSignatureSuccess}
                />

                <CategoryRequestSentModal
                  isOpen={isCategoryRequestSentModalOpen}
                  onClose={() => setCategoryRequestSentModalOpen(false)}
                />
              </Fragment>
            )}
          {currentUrl.includes('/search?searchType=Professional') && (
            <ExpertiseFilterProfessioanl />
          )}
          {currentUrl.includes('events') && (
            <>
              <CreateEvent />
            </>
          )}
          {currentUrl.includes('trending') && (
            <>
              <CreateTrend />
            </>
          )}
          {currentUrl.includes('/jobs') && (
            <>
              <CreateJob />

              <HiringJobsFilter />
            </>
          )}
        </>
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
};

export default Sidebar;
