import { Button, Modal, ModalBody } from 'reactstrap';
import styles from './createSignature.module.css';

type Props = {
  isOpen: any;
  onClose: any;
};
const CategoryRequestSentModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles['category-created-modal']}
    >
      <ModalBody>
        <div className={styles['category-created-modal-body']}>
          <p>
            New Category request sent <br /> Will be live within 24-48 hours
          </p>
          <hr style={{ width: '100%', margin: '15px 0 5px' }} />
          <Button onClick={onClose}>Okay</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CategoryRequestSentModal;
