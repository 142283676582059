import Lottie from 'react-lottie';
import failureJson from './failure.json';

type Props = {
  height?: number | string;
};

export const FailureLottie = ({ height }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: failureJson,
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} />
    </div>
  );
};
