import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import styles from './eventActions.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { deleteEvent } from 'src/services/event-service/event-service';
import { deleteJob } from 'src/services/job-service/job-service';

type Props = {
  postId: string;
  type: string;
};

export default function EventActions({ postId, type }: Props) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const router = useNavigate();

  const handleClick = async () => {
    if (type === 'job') {
      await deleteJob(postId);
    } else {
      await deleteEvent(postId);
    }
    router(0);
  };

  return (
    <Dropdown
      className={styles.menuButton}
      isOpen={showMenu}
      toggle={() => setShowMenu((old) => !old)}
      direction="down"
    >
      <DropdownToggle>
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <div onClick={() => handleClick()}>Delete</div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
