export const SEARCH_TYPE = {
  QUESTION: 'Question',
  PROFESSIONAL: 'Professional',
};

export type QUESTION_SORT = 'Most Recent' | 'Most Answered' | 'Up voted';
export type POST_FILTER = 'All' | 'Q & A' | 'Insight';

export const quesSortArr: QUESTION_SORT[] = ['Most Answered', 'Most Recent'];
export const postFilterArr: POST_FILTER[] = ['All', 'Q & A', 'Insight'];

export type questionSortCol = 'createdOn' | 'commentCount';

export const questionSortColMap: { [key: string]: string } = {
  'Most Recent': 'createdOn',
  'Most Answered': 'commentCount',
};

export const postFilterColMap: { [key: string]: string } = {
  All: '',
  'Q & A': 'QUESTION',
  Insight: 'WORK',
};
