import { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styles from './userUpdateModal.module.css';
interface ContactNumberModalProps {
  isOpen: boolean;
  toggle: () => void;
  contactNumber: string;
  setContactNumber: (contactNumber: string) => void;
  onUpdate: () => void;
  userContactNumber: any;
}
const ContactNumberModal: React.FC<ContactNumberModalProps> = ({
  isOpen,
  toggle,
  contactNumber,
  setContactNumber,
  onUpdate,
  userContactNumber,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      centered={isMobile}
      toggle={toggle}
      size={window.innerWidth > 700 ? 'md' : 'sm'}
      className={styles['location-update-modal']}
    >
      <ModalHeader toggle={toggle} className={styles['location-modal-header']}>
        Update Contact Number
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            fontSize: '20px',
            alignItems: 'center',
          }}
        >
          <Input
            className={styles['update-user-input']}
            type="text"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            placeholder={userContactNumber ? userContactNumber : '98281 XXXXX'}
          />
          <Button className={styles['user-contact-submit']} onClick={onUpdate}>
            Submit
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ContactNumberModal;
